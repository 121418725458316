import {genSortedDocs} from './generic';

const {
  docs: customers, loaded, sort, sorted,
  detailsID, details, isDetailsOpen,
} = genSortedDocs('customers');

export default {
  customers, loaded, sort, sorted,
  detailsID, details, isDetailsOpen,
};