import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles({
  alertButton: {
    color: '#FF0D0D',
  },
});

const RejectAlertDialog = ({
  open, handleClose, handleReject, submitting,
}) => {
  const styles = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="reject-alert"
      aria-describedby="reject-alert-description"
    >
      <DialogTitle id="reject-alert">
        Confirm Quote Rejection
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="reject-alert-description">
          Are you sure?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {
          submitting ? (
            <CircularProgress />
          ) : (
            <>
              <Button onClick={handleClose}>
                Cancel
              </Button>
              <Button className={styles.alertButton} onClick={handleReject}>
                Reject Quote Request
              </Button>
            </>
          )
        }
      </DialogActions>
    </Dialog>
  );
};

RejectAlertDialog.propTypes = {
  submitting: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
};

RejectAlertDialog.defaultProps = {};

export default RejectAlertDialog;
