import React from "react";
import {makeStyles} from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles({
  indexFilter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "solid 1px gray",
    height: "46px",
  },
  filterButton: {
    height: "28px",
    fontSize: "11px",
    width: "120px",
  },
  popoverContent: {
    display: "flex",
    flexDirection: "column",
  },
});

function IndexFilter(props) {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleButtonClick = (func) => {
    func();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={styles.indexFilter}>
      <div>
        {props.custom}
      </div>
      <Button
        classes={{root: styles.filterButton}}
        variant="outlined"
        aria-owns={"mouse-over-popover"}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
      >
        Sort
      </Button>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className={styles.popoverContent}>
          {
            props.filterButtons.map((b, i) => {
              return <Button onClick={() => handleButtonClick(b.func)} key={`${i}-button`}>{b.label}</Button>
            })
          }
        </div>
      </Popover>
    </div>
  );
}

export default IndexFilter;
