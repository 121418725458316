import React from 'react';
import isDarkColorHsp from 'is-dark-color-hsp';
import {makeStyles} from "@material-ui/styles";
import stc from 'string-to-color';

const useStyles = makeStyles({
    badge: {
        borderRadius: '50px',
        paddingInline: '6px',
        paddingBlock: '2px',
        fontSize: '12px',
    },
});


export default function Badge({text}) {
    const styles = useStyles();
    
    const backgroundColor = stc(text);
    const color = isDarkColorHsp.isDark(backgroundColor) ? 'white' : 'black';

    if (!text) return null;

    return <span className={styles.badge} style={{backgroundColor, color}}>
        {text}
    </span>
}