/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {useSetRecoilState, useRecoilValue} from 'recoil';

import state from '../../state';
import {getCustomers, getMovers, getDiscounts} from '../../firebase';

import CustomersIndex from './Index/CustomersIndex';
import CustomerDetail from './Detail/CustomerDetail';
import Loading from '../Loading';
import {useClaims} from 'util/useClaims';

function CustomersPanel() {
  const {isMover, loadingClaims, moverId, isAdmin} = useClaims();
  const setCustomers = useSetRecoilState(state.customers.customers)
  const cusLoaded = useRecoilValue(state.customers.loaded);
  const cusDetailsOpen = useRecoilValue(state.customers.isDetailsOpen);
  const setMovers = useSetRecoilState(state.movers.movers);
  const movLoaded = useRecoilValue(state.movers.loaded);
  const setDiscounts = useSetRecoilState(state.discounts.discounts);
  const disLoaded = useRecoilValue(state.discounts.loaded);
  
  useEffect(() => {
    if (!loadingClaims) {
      if (!cusLoaded)
        getCustomers(setCustomers, !isAdmin ? moverId : '');
      
      if (!movLoaded)
        getMovers(setMovers);
  
      if (!disLoaded)
        getDiscounts(setDiscounts);
    }

  }, [loadingClaims]);

  return (
    (!cusLoaded || !movLoaded || !disLoaded) ? <Loading />
    : cusDetailsOpen ? <CustomerDetail isMover={isMover && !isAdmin} />
    : <CustomersIndex isMover={isMover&&!isAdmin} />
  );
}

export default CustomersPanel;
