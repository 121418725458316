/** Deprecated in v2 */

import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {$tr} from '../../../../../util/util';
import DetailLine from '../../DetailLine';

const useClasses = makeStyles({
  accounting: {
    marginTop: 30,
    marginBottom: 30,
  },
  title: {
    fontSize: 30,
  },
  section: {
    marginTop: 30,
  },
  majorDetail: {
    marginBottom: 5,
  },
  subDetail: {
    marginBottom: 5,
    paddingLeft: 20,
    '&:last-child': {
      paddingBottom: 15,
    },
  },
});

const Accounting = ({details}) => {
  const cn = useClasses();

  const {accounting} = details;
  if (!accounting) return null;
  const {charge, mover, muva /* referral, priceAdjustment */} = accounting;
  if (!charge || !mover || !muva) return null;

  return (
    <div className={cn.accounting}>
      <span className={cn.title}>Accounting</span>
      <div className={cn.section}>
        <DetailLine className={cn.majorDetail} label="Mover Invoice" values={[$tr(charge.mover)]}/>
        <DetailLine className={cn.subDetail} label="Carrier/Stripe Fee" values={[$tr(mover.carrierFee)]}/>
        {!!mover.referralFee && <DetailLine className={cn.subDetail} label="Referral Fee" values={[$tr(mover.referralFee)]}/>}
        <DetailLine className={cn.subDetail} label="Payout" values={[$tr(mover.payout)]}/>
        <DetailLine className={cn.subDetail} label="Payout State" values={[mover.payoutState]}/>

        <DetailLine className={cn.majorDetail} label="Muva Fee" values={[$tr(charge.muva)]}/>
        <DetailLine className={cn.subDetail} label="Stripe Fee" values={[$tr(muva.stripeFee) || 'TBD']}/>
        {!!muva.referralFee && <DetailLine className={cn.subDetail} label="Referral Fee" values={[$tr(muva.referralFee)]}/>}
        {!!muva.connectedPayoutFee && 
          <DetailLine className={cn.subDetail} label="Connected Payout Fee" values={[$tr(muva.connectedPayoutFee)]}/>
        }
        {!!muva.connectedVolumeFee &&
          <DetailLine className={cn.subDetail} label="Connected Volume Fee" values={[$tr(muva.connectedVolumeFee)]}/>
        }
        {!!muva.connectedAccountFee &&
          <DetailLine className={cn.subDetail} label="Connected Account Fee" values={[$tr(muva.connectedAccountFee)]}/>
        }
        <DetailLine className={cn.subDetail} label="Payout" values={[$tr(muva.payout) || 'TBD']}/>
        <DetailLine className={cn.subDetail} label="Payout State" values={[muva.payoutState]}/>
        
        <DetailLine label="Customer Charge" values={[$tr(charge.total)]}/>
      </div>
    </div>
  )
};

export default Accounting;
