import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// const useStyles = makeStyles({});

const ErrorDialog = ({
  open, handleClose, title, message,
}) => {
  const messageList = message instanceof Array
    ? message.map((err, i) => <DialogContentText key={`error-${i}`}>{err}</DialogContentText>)
    : null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="error-alert"
      aria-describedby="error-alert-description"
    >
      <DialogTitle id="error-alert">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="error-alert-description">
          Error: {typeof message === 'string' ? message : ''}
        </DialogContentText>
        { !!messageList && messageList }
        <DialogContentText id="error-alert-contact">
          Please contact us if you continue getting this error
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Close
      </Button>
      </DialogActions>
    </Dialog>
  );
}


ErrorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.any, //string or Array
};

ErrorDialog.defaultProps = {
  message: '',
};

export default ErrorDialog;
