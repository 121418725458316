/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import state from '../../state';
import Loading from '../Loading';
import MoversIndex from './Index/MoversIndex';
import MoverDetail from './Detail/MoverDetail';
import {getMovers} from '../../firebase';

function MoversPanel() {
  const moversLoaded = useRecoilValue(state.movers.loaded);
  const setMovers = useSetRecoilState(state.movers.movers);
  const isDetailsOpen = useRecoilValue(state.movers.isDetailsOpen);

  useEffect(() => {!moversLoaded && getMovers(setMovers)}, []);

  return (
    !moversLoaded ? <Loading />
      : isDetailsOpen ? <MoverDetail />
      : <MoversIndex />
  );
}

export default MoversPanel;
