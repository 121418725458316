import {AdminStates, getQuoteStateText, getStateText} from 'util/status';

import {List} from "@material-ui/core";
import ListingRow from "./ListingRow";
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import state from '../../../../state';
import {useClaims} from 'util/useClaims';
import {useSetRecoilState} from 'recoil';

const assignments = (quote) => {
  return _.map(
    quote.assigns,
    assign => `${assign.proName} - ${getStateText(assign.state)}`,
  ).join('\n');
};

const services = (quote) => {
  return _.map(
    quote.services,
    service => `${service.type} - ${getStateText(service.state)}`,
  ).join('\n');
}

const CustomerListing = (props) => {
  const {data} = props;
  const {isAdmin} = useClaims();
  const setDetailsID = useSetRecoilState(state.customers.detailsID)
  const onDetailOpen = (details) => {
    window.scrollTo({top: 0, behavior: "smooth"});
    setDetailsID(details.id);
  };

  return (
    <List>
      {data.map(quote => {
        const {lastModified, pickup, contact, version} = quote;
        const formattedModified = lastModified
          ? moment(lastModified * 1000).format("MMM D, YYYY, h:mma")
          : null;
        const middleSecondary
          = quote.state === AdminStates.review ? assignments(quote)
          : quote.state === AdminStates.ongoing ? services(quote)
          : null
        const bedrooms = pickup && pickup.bedroomCount ? ` - ${pickup.bedroomCount} Bedrooms` : '';
        const status = version === 1 ? getQuoteStateText(quote.state) : getStateText(quote.state);

        if (quote && quote.contact) 
          return (
            <ListingRow
              data={quote}
              leftPrimary={contact.name}
              leftSecondary={<><span>ID: {quote.id}</span><br/>{quote.moveType && `${quote.moveType} ${bedrooms}`}</>}
              middlePrimary={`Status: ${status}`}
              middleSecondary={middleSecondary}
              rightPrimary={formattedModified}
              buttonLabel={'Details'}
              buttonAction={onDetailOpen}
              key={quote.id}
              badgeField={isAdmin ? 'appID': ''}
            />
          )
         else 
          return null;
        
      })}
    </List>
  );
};

export default CustomerListing;
