import React, {useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {TextField, FormControl, Select, InputLabel, MenuItem} from '@material-ui/core';

import {ActionDiv, ActionButton} from 'lib';
import ErrorDialog from "../../ErrorDialog";
import {AdminStates, getStateText} from '../../../../util/status';
import {closeRequest} from '../../../../firebase';

const CLOSE_STATES = [
  AdminStates.adminRejected,
  AdminStates.customerRejected,
  AdminStates.providerRejected,
  AdminStates.expired,
];

const useStyles = makeStyles({
  flexcol: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "25px",
    marginBottom: "5px",
    color: "#176BB5",
  },
  info: {
    fontSize: "12px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    marginTop: "15px",
  },
  formLine: {
    marginTop: '20px',
  },
  submitContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  switchLabel: {
    fontSize: '12px',
  },
});

const CloseRequestForm = ({details, collapse, onClose}) => {
  const cn = useStyles();

  const [closeState, setCloseState] = useState(null);
  const [notes, setNotes] = useState(null);

  const [errorMsg, setErrorMsg] = useState('');
  const [uploading, setUploading] = useState(false);

  const onChange = setFunc => event => setFunc(event.target.value);

  const isFormComplete = !!closeState;

  const handleSubmitClick = () => {
    if (uploading) return;
    setUploading(true);
    return closeRequest(details.id, closeState, notes)
      .finally(() => setUploading(false))
      .then(onClose)
      .catch(error => setErrorMsg(error.message))
  };

  return (
    <ActionDiv collapse={collapse} onClose={onClose} log>
      <div className={cn.flexcol}>
        <span className={cn.title}>Close Request</span>
        <div className={cn.info}>
          Set the final state and close the request.
        </div>
      </div>
      <div className={cn.form}>
        <FormControl>
          <InputLabel htmlFor="state-select" shrink>Final State</InputLabel>
          <Select
            value={closeState || ''}
            onChange={onChange(setCloseState)}
            inputProps={{
              name: 'Final State',
              id: 'state-select',
            }}>
            {CLOSE_STATES.map(state => 
              <MenuItem key={state} value={state}>{getStateText(state)}</MenuItem>,
            )}
          </Select>
        </FormControl>
        <TextField
          className={cn.formLine}
          label="Close Notes"
          value={notes}
          InputLabelProps={{shrink: true}}
          id="notes"
          multiline={true}
          rows={4}
          onChange={onChange(setNotes)}
        />
        <div className={cn.submitContainer}>
          <ActionButton 
            onClick={handleSubmitClick} 
            disabled={!isFormComplete}
            loading={uploading}>
            Close Request
          </ActionButton>
        </div>
        <ErrorDialog
          open={!!errorMsg}
          handleClose={() => setErrorMsg('')}
          title="Uh oh! Something Went Wrong."
          message={errorMsg}
        />
      </div>
    </ActionDiv>
  );
}

export default CloseRequestForm;
