import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Player, BigPlayButton, LoadingSpinner} from 'video-react';
import {makeStyles} from '@material-ui/styles';
import poster from '../../../../assets/poster.png';
import {getVideoURL} from '../../../../firebase';

const useStyles = makeStyles({
  videoPlayer: {
    position: 'relative',
  },

  video: {
    opacity: 0,
    visibility: 'hidden',
    transition: '300ms',
  },

  image: {
    opacity: 0,
    visibility: 'hidden',
    transition: '300ms',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '530px',
    height: '310px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },

  show: {
    opacity: 1,
    visibility: 'visible',
  },
});

const VideoPlayer = ({filename}) => {
  const playerRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [mimeType, setMimeType] = useState(null);
  const styles = useStyles();

  useEffect(() => {
    setVideoUrl(null);
    setMimeType(null);
    getVideoURL(filename).then((url) => setVideoUrl(url));
  }, [filename]);

  useEffect(() => {
    if (videoUrl) {
      fetch(videoUrl)
        .then((response) => {
          const tmpMime = response.headers.get('Content-Type').split('/')[0];
          setMimeType(tmpMime);
          if (tmpMime === 'video') playerRef.current.load();
        })
        .catch((error) => console.error('Error:', error));
    }
  }, [videoUrl]);

  return (
    <div className={styles.videoPlayer}>
      <Player
        ref={playerRef}
        playsInline
        poster={poster}
        fluid={false}
        width={530}
        height={310}
        key={`${videoUrl}-key`}
        className={
          styles.video + (mimeType === 'video' ? ' ' + styles.show : '')
        }
      >
        <BigPlayButton position="center" />
        <LoadingSpinner />
        <source src={videoUrl} />
      </Player>
      <div
        className={
          styles.image + (mimeType === 'image' ? ' ' + styles.show : '')
        }
        style={{backgroundImage: `url(${videoUrl})`}}
      />
    </div>
  );
};

VideoPlayer.propTypes = {
  filename: PropTypes.string,
};

VideoPlayer.defaultProps = {
  filename: null,
};

export default VideoPlayer;
