import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ActionButton} from 'lib';
import {makeStyles} from '@material-ui/styles';
import useFileInput from 'util/useFile';
import {uploadMoverLogo} from '../../../firebase';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    fileInput: {
        display: 'none',
    },
    image: {
        width: '200px',
        marginTop: '5px',
    },
})

export default function LogoFile({details = {}}) {
    const [loading, setLoading] = useState(false);
    const styles = useStyles();
    const inputFileRef = useRef();
    const {file, setFile, onFileChange, openFileDialog} = useFileInput(inputFileRef);

    const uploadFile = useCallback(async () => {
        setLoading(true);
        await uploadMoverLogo(file, details.id);
        setFile(null);
        setLoading(false);
    }, [setLoading, setFile, file, details.id])

    useEffect(() => {
        if (file) 
            uploadFile();
    }, [file, uploadFile])

    return <div className={styles.container}>
        <input type='file' accept="image/*" className={styles.fileInput} ref={inputFileRef} onChange={onFileChange}/>
        <ActionButton variant="contained" color="primary" loading={loading} onClick={openFileDialog}>
            {
                details.logoUrl ? 'Update logo' : 'Add logo'
            }
        </ActionButton>
        {
            details.logoUrl && <img src={details.logoUrl} alt='Logo img' className={styles.image} />
        }
    </div>
}