import firebase from "firebase/compat/app";
import * as moment from 'moment';
import 'moment-timezone';
const Timestamp = firebase.firestore.Timestamp;

export function statesObj() {
  return {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming",
  };
}

export const states = [
  {name: 'Alabama', abbrev: 'AL'},
  {name: 'Alaska', abbrev: 'AK'},
  {name: 'Arizona', abbrev: 'AZ'},
  {name: 'Arkansas', abbrev: 'AR'},
  {name: 'California', abbrev: 'CA'},
  {name: 'Colorado', abbrev: 'CO'},
  {name: 'Connecticut', abbrev: 'CT'},
  {name: 'Delaware', abbrev: 'DE'},
  {name: 'Florida', abbrev: 'FL'},
  {name: 'Georgia', abbrev: 'GA'},
  {name: 'Hawaii', abbrev: 'HI'},
  {name: 'Idaho', abbrev: 'ID'},
  {name: 'Illinois', abbrev: 'IL'},
  {name: 'Indiana', abbrev: 'IN'},
  {name: 'Iowa', abbrev: 'IA'},
  {name: 'Kansas', abbrev: 'KS'},
  {name: 'Kentucky', abbrev: 'KY'},
  {name: 'Louisiana', abbrev: 'LA'},
  {name: 'Maine', abbrev: 'ME'},
  {name: 'Maryland', abbrev: 'MD'},
  {name: 'Massachusetts', abbrev: 'MA'},
  {name: 'Michigan', abbrev: 'MI'},
  {name: 'Minnesota', abbrev: 'MN'},
  {name: 'Mississippi', abbrev: 'MS'},
  {name: 'Missouri', abbrev: 'MO'},
  {name: 'Montana', abbrev: 'MT'},
  {name: 'Nebraska', abbrev: 'NE'},
  {name: 'Nevada', abbrev: 'NV'},
  {name: 'New Hampshire', abbrev: 'NH'},
  {name: 'New Jersey', abbrev: 'NJ'},
  {name: 'New Mexico', abbrev: 'NM'},
  {name: 'New York', abbrev: 'NY'},
  {name: 'North Carolina', abbrev: 'NC'},
  {name: 'North Dakota', abbrev: 'ND'},
  {name: 'Ohio', abbrev: 'OH'},
  {name: 'Oklahoma', abbrev: 'OK'},
  {name: 'Oregon', abbrev: 'OR'},
  {name: 'Pennsylvania', abbrev: 'PA'},
  {name: 'Rhode Island', abbrev: 'RI'},
  {name: 'South Carolina', abbrev: 'SC'},
  {name: 'South Dakota', abbrev: 'SD'},
  {name: 'Tennessee', abbrev: 'TN'},
  {name: 'Texas', abbrev: 'TX'},
  {name: 'Utah', abbrev: 'UT'},
  {name: 'Vermont', abbrev: 'VT'},
  {name: 'Virginia', abbrev: 'VA'},
  {name: 'Washington', abbrev: 'WA'},
  {name: 'West Virginia', abbrev: 'WV'},
  {name: 'Wisconsin', abbrev: 'WI'},
  {name: 'Wyoming', abbrev: 'WY'},
];

export const $tr = (cents) => {
  return isNaN(cents) ? null
    : (cents % 100) === 0 ? `$${cents/100}`
    : `$${(cents/100).toFixed(2)}`;
}

export const time = 
  (input = Date.now(), timezone ='America/Los_Angeles') => {  
  // input normalization for Moment
  const arg = input instanceof Timestamp ? input.toMillis() : input;
  // convert to moment
  return moment.tz(arg, timezone);
};

export const capitalize = (str) => {
  return str?.replace(/\b\w/g, l => l.toUpperCase());
};

export const dateStr
  = (t) => time(t).format("MMM D, YYYY");
export const timeStr
  = (t) => time(t).format('h:mm a')
export const dateTimeStr 
  = (t) => time(t).format("MMM D, YYYY - h:mm a");
export const shortDateStr
  = (t) => time(t).format("MM/D/YY");
  // time(t).format("h:mm A z - MMMM Do YYYY");

export const hashCode = (str) => {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
      let char = str.charCodeAt(i);
      hash = ((hash<<5)-hash)+char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}