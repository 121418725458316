import 'libphonenumber-js/metadata.min.json';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, {useState} from "react";
import {countries, states} from 'util/countries';
import {createMover, updateMover} from '../../firebase';

import {ActionButton} from 'lib';
import {AsYouType} from 'libphonenumber-js';
import ErrorDialog from './ErrorDialog';
import MaskedInput from 'react-text-mask';
import {TagInput} from 'react-simple-tagged-input';
import {makeStyles} from "@material-ui/styles";
import state from 'state';
import {useRecoilValue} from 'recoil';

const useStyles = makeStyles({
  editForm: {
    display: "flex",
    flexDirection: "column",
  },
  submitContainer: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "center",
  },
  formLine: {
    marginTop: "15px",
  },
  formSplitLine: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
  },
  splitField: {
    width: "150px",
  },
  phoneLabel: {
    fontSize: "13px",
  },
  phoneField: {
    width: '100%',
  },
});

const URLMask = ({inputRef, ...other}) => (
  <MaskedInput
    {...other}
    ref={ref => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={(rawValue) => {
      if (!rawValue) return false;
      const len = /^https:\/\//.test(rawValue)
        ? rawValue.trim().length - 8
        : rawValue.trim().length;
      const mask = ['h', 't', 't', 'p', 's', ':', '/', '/'].concat(new Array(len).fill(/\S/));
      return mask;
    }}
    placeholderChar={'\u2000'}
    placeholder='https://'
  />
);

const MoverForm = ({create, onSuccess}) => {
  const styles = useStyles();
  const defaultCountry = 'USA';
  const mover = useRecoilValue(state.movers.details) || {};

  const [uploading, setUploading] = useState(false);
  const [extraEmails, setExtraEmails] = useState(mover.extraEmails || []);
  const [inputs, setInputs] = useState({
    ...mover,
    state: mover?.state || '',
    country: mover.country ?? defaultCountry,
  });
  const [errorMsg, setErrorMsg] = useState('');

  const countryOptions = countries.map((r, key) => <MenuItem value={r.value} key={key}>{r.flag} {r.text}</MenuItem>);

  const stateOptions = (states[inputs.country] || []).map((r, key) => <MenuItem value={r.value} key={key}>{r.text}</MenuItem>);

  const countryObj = countries.find((r) => r.value === (inputs.country || defaultCountry));

  const handleInputChange = (name) => (event) => {
    if (event.persist) event.persist();
    const {value} = event.target;
    if (name === 'phone') {
      if (Number.isFinite(parseInt(value))) {
        const formattedPhone = new AsYouType(inputs.country || defaultCountry).input(value);
        setInputs(inputs => ({...inputs, phone: formattedPhone}));
      }
    } else {
      setInputs(inputs => ({...inputs, [name]: value}));
    }
    // if (name === 'country') {
    //   const foundCountry = countries.find((r) => r.value === value);
    //   setInputs(inputs => ({...inputs, 'phone': `+${foundCountry.countryCode}`}));
    // }
  };

  const onPhoneKeyDown = (event) => {
    const {key, ctrlKey, metaKey} = event;
    const specialKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', '+', 'Space', '(', ')'];
    const isCopyEvent = (ctrlKey || metaKey) && (key === 'c' || key === 'v');
    if (!Number.isFinite(parseInt(`${key}`)) && !specialKeys.includes(key) && !isCopyEvent) 
      event.preventDefault();
    
  }

  const payload = {
    ...inputs,
    extraEmails,
  }

  const handleCreateMover = () => {
    setUploading(true);
    createMover(payload)
      .then(() => onSuccess && onSuccess())
      .catch((err) => setErrorMsg(err.message))
      .finally(() => setUploading(false));
  }

  const handleSubmitClick = () => {
    setUploading(true);
    updateMover(mover.id, payload)
      .then(() => onSuccess && onSuccess())
      .catch((err) => setErrorMsg(err.message))
      .finally(() => setUploading(false));
  };

  const handleErrorClose = () => setErrorMsg('');

  const handleChangeExtraEmail = (ev) => {
    setExtraEmails(ev);
  }

  const isFormComplete =
    inputs.name &&
    inputs.email &&
    inputs.phone &&
    inputs.url &&
    inputs.country;
  
  return (
    <div className={styles.editForm}>
      <TextField
        className={styles.formLine}
        id="company-name"
        label="Company Name"
        InputLabelProps={{shrink: true}}
        value={inputs.name}
        onChange={handleInputChange("name")}
        required
      />
      <TextField
        className={styles.formLine}
        id="contact-person"
        label="Contact Person"
        InputLabelProps={{shrink: true}}
        value={inputs.contact}
        onChange={handleInputChange("contact")}
      />
      <TextField
        className={styles.formLine}
        id="email"
        label="Email"
        InputLabelProps={{shrink: true}}
        value={inputs.email}
        onChange={handleInputChange("email")}
        required
      />
      <FormControl style={{marginTop: '10px'}}>
        <InputLabel htmlFor="country-native-select" shrink>Extra emails</InputLabel>
        <TagInput
          defaultData={extraEmails}
          onInputChange={handleChangeExtraEmail}
          autoFocus={false}
          style={{  
            borderBottom: '1px solid black',
            paddingBlock: '10px',
            marginTop: '8px',
          }}
          placeholder=' '
      />
      </FormControl>
      <div className={styles.formLine + " " + styles.formSplitLine}>
        <FormControl>
          <InputLabel htmlFor="country-native-select" shrink>Country *</InputLabel>
          <Select
            onChange={handleInputChange("country")}
            className={styles.splitField}
            inputProps={{
              name: 'country',
              id: 'country-native-select',
            }}
            required
            value={inputs.country}
          >
            <MenuItem value={undefined}>
              <em>Country</em>
            </MenuItem>
            {countryOptions}
          </Select>
        </FormControl>
        <TextField
          id="phone"
          label="Phone"
          InputLabelProps={{shrink: true}}
          disabled={!inputs.country}
          value={inputs.phone}
          onKeyDown={onPhoneKeyDown}
          onChange={handleInputChange("phone")}
          placeholder={countryObj.placeholder}
          required
        />
      </div>
      <TextField
        className={styles.formLine}
        id="url"
        label="Website"
        InputLabelProps={{shrink: true}}
        InputProps={{inputComponent: URLMask}}
        value={inputs.url}
        onChange={handleInputChange("url")}
        required
      />
      <TextField
        className={styles.formLine}
        id="address-1"
        label="Address Line 1"
        InputLabelProps={{shrink: true}}
        value={inputs.address1}
        onChange={handleInputChange("address1")}
      />
      <TextField
        className={styles.formLine}
        id="address-2"
        label="Address Line 2"
        InputLabelProps={{shrink: true}}
        value={inputs.address2}
        onChange={handleInputChange("address2")}
      />
      <TextField
        className={styles.formLine}
        id="city"
        label="City"
        InputLabelProps={{shrink: true}}
        value={inputs.city}
        onChange={handleInputChange("city")}
      />
      <div className={styles.formLine + " " + styles.formSplitLine}>
        <FormControl>
          <InputLabel htmlFor="state-native-select" shrink>State</InputLabel>
          <Select
            value={inputs.state}
            onChange={handleInputChange("state")}
            className={styles.splitField}
            inputProps={{
              name: 'state',
              id: 'state-native-select',
            }}
          >
            <MenuItem value={undefined}>
              <em>State</em>
            </MenuItem>
            {stateOptions}
          </Select>
        </FormControl>
        <TextField
          className={styles.splitField}
          label="Zip"
          InputLabelProps={{shrink: true}}
          id="zip"
          value={inputs.zip}
          onChange={handleInputChange("zip")}
        />
      </div>
      <TextField
        className={styles.formLine}
        label="Additional Info"
        value={inputs.info}
        InputLabelProps={{shrink: true}}
        id="info"
        multiline={true}
        onChange={handleInputChange("info")}
      />
      <TextField
        className={styles.formLine}
        label="Blurb"
        value={inputs.blurb}
        InputLabelProps={{shrink: true}}
        id="blurb"
        multiline={true}
        onChange={handleInputChange("blurb")}
      />
      <TextField
        className={styles.formLine}
        label="App ID"
        value={inputs.appID}
        InputLabelProps={{shrink: true}}
        id="appID"
        multiline={true}
        onChange={handleInputChange("appID")}
      />
      <div className={styles.submitContainer}>
        <ActionButton
            onClick={create ? handleCreateMover : handleSubmitClick}
            disabled={!isFormComplete}
            loading={uploading}
          >
            {create ? 'Create New Mover' : 'Submit Edit'}
        </ActionButton>
      </div>

      <ErrorDialog
        open={!!errorMsg}
        handleClose={handleErrorClose}
        title="Uh oh! Something Went Wrong."
        message={errorMsg}
      />
    </div>
  );
}

export default MoverForm;

