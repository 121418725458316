import React, {useState, useEffect} from "react";
import {IconButton, Popover, Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import * as Firebase from "../../../../firebase";
import DetailLine from "../DetailLine";

const UserInfo = ({userID}) => {
  const cn = useStyles();

  const [anchor, setAnchor] = useState(null);
  const openPopover = (evt) => setAnchor(evt.currentTarget);
  const closePopover = () => setAnchor(null);

  const [userProfile, setUserProfile] = useState(null);
  
  useEffect(() => {
    Firebase.getUser(userID)  
      .then(ret => setUserProfile(ret));
  }, [setUserProfile, userID]);

  return <>
    <IconButton className={cn.button} onClick={openPopover}>
      <AccountCircleIcon />
    </IconButton>
    <Popover 
      open={!!anchor}
      onClose={closePopover}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
        <div className={cn.popover}>
          {!userProfile ?
            <Typography>Loading...</Typography>
          : <>
              <DetailLine label="Attribution" values={[userProfile.attribution]} />
              <DetailLine label="Device" 
                values={[`${userProfile.deviceInfo?.brand || ''} ${userProfile.deviceInfo?.model || ''}`]} />
          </>}
        </div>
    </Popover>
  </>;
};

const useStyles = makeStyles({
  button: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  popover: {
    minWidth: 300,
    padding: 20,
  },
});

export default UserInfo