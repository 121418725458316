import _ from 'lodash';

export const sortedObjectsBy = (objects, key, direction) => {
  console.log('sortedObjectsBy')
  return _.orderBy(objects, function (e) {
    const value = _.get(e, key);
    if (typeof value === 'string') 
      return value.toLowerCase();
    
    return value;
  }, direction);
};

export const moverSort = (movers, key, dir) => {
  return _.orderBy(movers, function (e) {
    const value = _.get(e, key);
    if (typeof value === 'string') 
      return value.toLowerCase();
    
    return value;
  }, dir);
}