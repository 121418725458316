import React from 'react';
import {makeStyles} from "@material-ui/styles";


const useStyles = makeStyles({
  indexHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '36px',
  },
  panelTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
  },
});

function IndexHeader(props) {
  const styles = useStyles();

  return (
    <div className={styles.indexHeader}>
      <span className={styles.panelTitle}>{props.title}</span>
      <div>{props.toolbar}</div>
    </div>
  );
}

export default IndexHeader;
