import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/styles";
import {NavigateBefore, NavigateNext} from "@material-ui/icons";
import {IconButton} from '@material-ui/core';
import VideoPlayer from "./VideoPlayer";

const useStyles = makeStyles({
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10,
  },
  videoLabel: {
    width: 50,
    padding: "0 10px",
    textAlign: 'center',
    fontSize: 20,
  },
});

const VideoSelect = ({orderID, videos}) => {
  const classes = useStyles();
  const [curVideo, setCurVideo] = useState(0);

  const filename = videos[curVideo];
  const filepath = 
    filename.includes('sample') ? `videos/sample/${filename}`
    : `videos/${orderID}/${filename}`;
  const numVideos = videos ? videos.length : 1;
  
  return (
    <div>
      <VideoPlayer filename={filepath} />
      {videos && (
        <div className={classes.iconRow}>
          <IconButton 
            onClick={() => setCurVideo(curVideo - 1)}
            disabled={curVideo <= 0}
            children={<NavigateBefore />}
          />
          <span className={classes.videoLabel}>{curVideo+1} / {numVideos}</span>
          <IconButton
            onClick={() => setCurVideo(curVideo + 1)}
            disabled={curVideo >= videos.length-1}
            children={<NavigateNext />}
          />
        </div>
      )}
    </div>
  );
};

VideoSelect.propTypes = {
  videos: PropTypes.array,
  videoFilename: PropTypes.string,
}

VideoSelect.defaultType = {
  videos: null,
  videoFilename: null,
}

export default VideoSelect;