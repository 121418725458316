import React from 'react';
import {makeStyles} from "@material-ui/styles";

import {Tabs, Tab} from "@material-ui/core";


const useStyles = makeStyles({
  indexTabs: {
    borderBottom: 'solid 1px gray',
    marginTop: '20px',
  },
});

function IndexTabs(props) {
  const styles = useStyles();

  return (
    <Tabs
      value={props.currentTab}
      onChange={props.handleChange}
      indicatorColor='primary'
      textColor='primary'
      classes={{root: styles.indexTabs}}
    >
      {props.tabs.map(tab => {
        return (
          <Tab value={tab.value} label={tab.label} key={`${tab.label}-tab`} />
        )
      })}
    </Tabs>
  );
}

export default IndexTabs;
