import React from "react";
import {RecoilRoot} from 'recoil';
import {create} from 'jss';
import jssExtend from 'jss-plugin-extend';
import {makeStyles, StylesProvider, jssPreset} from '@material-ui/styles';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Main from './Main';
import OnboardingForm from "components/OnboardingForm/Index";

const jss = create({plugins: [jssExtend(), ...jssPreset().plugins]});

const theme = createTheme(({
  overrides: {
    // makes on hover underlines less harsh
    MuiInput: {
      underline: {
        "&&&&:hover:before": {
          borderBottom: "1.6px solid rgba(0, 0, 0, 0.45)",
        },
      },
    },
  },
}));

const useStyles = makeStyles({
  appContainer: {
    height: "100%",
  },
});

const params = (new URL(document.location)).searchParams;
const action = params.get('action') || '';
const isFormOnboardingRoute = action === "onboarding";

function App() {
  const styles = useStyles();

  return (
    <RecoilRoot>
    <StylesProvider jss={jss}>
    <MuiThemeProvider theme={theme}>
      <div className={styles.appContainer}>
        {
          isFormOnboardingRoute ? <OnboardingForm /> : <Main />
        }
      </div>
    </MuiThemeProvider>
    </StylesProvider>
    </RecoilRoot>
  );
}

export default App;