import React, {useEffect, useState} from "react";
import {getOrderLinks, toggleLinkExpired} from '../../../../firebase';

import AsyncSwitch from '../AsyncSwitch';
import DetailLine from "../DetailLine";
import {Paper} from '@material-ui/core';
import _ from 'lodash';
import {makeStyles} from "@material-ui/styles";
import moment from 'moment';

function LinkInfo({details}) {
  const cn = useStyles();
  const liveLinks = process.env.NODE_ENV === 'production' || process.env.REACT_APP_STAGE;
  const [linkDetails, setLinkDetails] = useState(null);

  const timeStr = (millis) => 
    moment(millis).tz('America/Los_Angeles').format("h:mm A z - MMMM Do YYYY");

  // const linkDetails = [
  //   [ linkInfos?.mover,
  //     'Mover',
  //     'mover.themuva.com/review?linkID=',
  //     'localhost:3001/review?linkID='],
  //   [ linkInfos?.moverReview,
  //     'Mover Review Link',
  //     'mover.themuva.com/review?linkID=',
  //     'localhost:3001/review?linkID='],
  //   [ linkInfos?.customer,
  //     'Customer Link',
  //     'customer.themuva.com/?linkID=',
  //     'localhost:3002/?linkID='],
  //   [ linkInfos?.moverInvoice,
  //     'Mover Invoice Link',
  //     'mover.themuva.com/invoice?linkID=',
  //     'localhost:3001/invoice?linkID='],
  //   [ linkInfos?.feedback,
  //     'Feedback Link',
  //     'customer.themuva.com/feedback?linkID=',
  //     'localhost:3002/feedback?linkID='],
  // ];

  const updateLinks = () => {
    if (!details.links) return;
    getOrderLinks(details).then(links => setLinkDetails(
      _.map(links, (link, key) => [
        link,
        ...key === 'mover' ? [
            'Mover',
            'mover.themuva.com/review?linkID=',
            'localhost:3001/review?linkID='] 
          : key === 'moverReview' ? [
            'Mover Review Link',
            'mover.themuva.com/review?linkID=',
            'localhost:3001/review?linkID=']
          : key === 'customer' ? [
            'Customer Link',
            'customer.themuva.com/?linkID=',
            'localhost:3002/?linkID=']
          : key === 'moverInvoice' ? [
            'Mover Invoice Link',
            'mover.themuva.com/invoice?linkID=',
            'localhost:3001/invoice?linkID=']
          : key.includes('feedback') ? [
            'Feedback Link',
            'customer.themuva.com/feedback?linkID=',
            'localhost:3002/feedback?linkID=']
          : key.includes('pro_') ? [
            details.assigns[key.split('_')[1]].proName,
            'mover.themuva.com/review?linkID=',
            'localhost:3001/review?linkID=']
          : [],
        key,
      ]).sort(
        ([,,,,keyA], [,,,,keyB]) => {
          const sortVal = k => {
            if (k === 'customer') return 0;
            if (k.includes('feedback')) return 1;
            if (k === 'mover') return 2;
            return 3;
          } 

          return sortVal(keyA) - sortVal(keyB);
        },
      )),
    );
  }

  useEffect(updateLinks, [details]);

  if (!details.links)
    return null;

  return (
    <Paper elevation={1} className={cn.linkInfo}>
      <div>
        <span className={cn.infoTitle}>Links</span>
      </div>
      <div className={cn.infoSection}>
      {
        linkDetails?.map(([linkInfo, label, liveURL, devURL]) => 
          linkInfo && <div key={liveURL} className={cn.linkRoot}>
            <AsyncSwitch
              value={!linkInfo.expired} 
              onChange={() => toggleLinkExpired(linkInfo.id).then(updateLinks)}
              onError={err => alert(err.message)}/>
            <DetailLine
              key={label}
              label={label}
              link={liveLinks
                ? `${liveURL}${linkInfo.id}`
                : `${devURL}${linkInfo.id}`
              }
              strikeLink={linkInfo.expired}
              values={ linkInfo.expiresAt &&
                [`Expires: ${timeStr(linkInfo.expiresAt.toMillis())}`]
              }
              long/>
          </div>,
        )
      }
      </div>
    </Paper>
  );
};

const useStyles = makeStyles({
  linkRoot: {
    display: 'flex',
  },
  linkInfo: {
    marginTop: '30px',
    marginBottom: '30px',
    padding: '20px 20px 20px 20px',
    minWidth: 450,
  },
  infoTitle: {
    fontSize: '30px',
  },
  infoSection: {
    marginTop: '30px',
  },
});

export default LinkInfo;
