import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/styles";
import {TextField, FormControl, Select, InputLabel, MenuItem} from '@material-ui/core';

import {ActionDiv, ActionButton} from 'lib';
import {time} from '../../../../util/util';
import {setDeadline} from '../../../../firebase';
import ErrorDialog from "../../ErrorDialog";
import {AssignStates} from '../../../../util/status';

const useStyles = makeStyles({
  flexcol: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "25px",
    marginBottom: "5px",
    color: "#176BB5",
  },
  info: {
    fontSize: "12px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    marginTop: "15px",
  },
  formLine: {
    marginTop: '20px',
  },
  submitContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  switchLabel: {
    fontSize: '12px',
  },
});

const SetDeadlineForm = ({details, pros, collapse, onClose}) => {
  const cn = useStyles();
  const {assigns} = details;
  const availPros = pros.filter(pro => {
    const assign = assigns[pro.id];
    return assign && (
      assign.state === AssignStates.assigned
      || assign.state === AssignStates.quoted
    );
  });
  const [proID, setProID] = useState('');
  const [expiresDate, setExpiresDate] = useState('');
  const [expiresTime, setExpiresTime] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [uploading, setUploading] = useState(false);

  const assign = assigns[proID];
  const initDate = assign && time(assign.expiresAt).format('YYYY-MM-DD');
  const initTime = assign && time(assign.expiresAt).format('HH:mm');
  const isFormComplete 
    = proID && (expiresDate !== initDate || expiresTime !== initTime);

  useEffect(() => {
    if (proID) {
      setExpiresDate(initDate);
      setExpiresTime(initTime);
    } else {
      setExpiresDate('');
      setExpiresTime('');
    }
  }, [proID, initDate, initTime]);

  const onChange = setFunc => event => setFunc(event.target.value);

  const handleSubmitClick = () => {
    if (uploading) return;

    setUploading(true);
    const dateTime = time(`${expiresDate}T${expiresTime}:00`);
    return setDeadline(details.id, proID, dateTime.valueOf())
      .finally(() => setUploading(false))
      .then(onClose)
      .catch(error => setErrorMsg(error.message))
  };

  return (
    <ActionDiv collapse={collapse} onClose={onClose} log>
      <div className={cn.flexcol}>
        <span className={cn.title}>Set Deadline</span>
        <div className={cn.info}>
          Please set the date and time to the new deadline.
        </div>
      </div>
      <div className={cn.form}>
        <FormControl>
            <InputLabel htmlFor="assign-select" shrink>Assignment/Quote</InputLabel>
            <Select
              value={proID || ''}
              onChange={onChange(setProID)}
              inputProps={{
                name: 'Assignment/Quote',
                id: 'assign-select',
              }}>
              {availPros.map(pro =>
                <MenuItem value={pro.id} key={pro.id}>{pro.name}</MenuItem>,
              )}
            </Select>
        </FormControl>
        <TextField
          id="date"
          className={cn.formLine}
          label="Date"
          InputLabelProps={{shrink: true}}
          type="date"
          placeholder="mm/dd/yyy"
          value={expiresDate}
          onChange={onChange(setExpiresDate)}
          disabled={!proID}
          required
        />
        <TextField
          id="time"
          className={cn.formLine}
          label="Time"
          InputLabelProps={{shrink: true}}
          type="time"
          inputProps={{step: 300}}
          value={expiresTime}
          onChange={onChange(setExpiresTime)}
          disabled={!proID}
          required
        />
        <div className={cn.submitContainer}>
          <ActionButton 
            onClick={handleSubmitClick} 
            disabled={!isFormComplete}
            loading={uploading}>
            Set Deadline
          </ActionButton>
        </div>
        <ErrorDialog
          open={!!errorMsg}
          handleClose={() => setErrorMsg('')}
          title="Uh oh! Something Went Wrong."
          message={errorMsg}
        />
      </div>
    </ActionDiv>
  );
}

export default SetDeadlineForm;
