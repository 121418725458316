import {selector} from 'recoil';
import {genSortedDocs} from './generic';

const {
  docs: movers, loaded, sort, sorted,
  detailsID, details, isDetailsOpen,
} = genSortedDocs('movers');
const filtered = selector({
  key: 'movers/active',
  get: ({get}) => ({
    activeMovers: get(sorted).filter(m => m.active),
    inactiveMovers: get(sorted).filter(m => !m.active),
  }),
});

export default {
  movers, loaded, sort, sorted, filtered,
  detailsID, details, isDetailsOpen,
};
