import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/functions';

import {collection, getDocs, onSnapshot, or, query, setLogLevel, where} from 'firebase/firestore';
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage';
import {isEmpty, isUndefined, last, map} from 'lodash';

import firebase from 'firebase/compat/app';
import ls from 'localstorage-slim';
import moment from 'moment';
import {removeLocalData} from './util/localstorage';

const firebaseConfig = {
  apiKey: 'AIzaSyAqLMsS5QKyaavYDRjqnHwbOWde4M8yZyo',
  authDomain: 'muva-backend.firebaseapp.com',
  databaseURL: 'https://muva-backend.firebaseio.com',
  projectId: 'muva-backend',
  storageBucket: 'muva-backend.appspot.com',
  messagingSenderId: '349722643142',
  appId: '1:349722643142:web:8ea00877c238394c5ba5c7',
};

const devConfig = {
  apiKey: 'AIzaSyAx-i3luVOn2d4iIFc1R9hCvC6XVWIaP08',
  authDomain: 'muva-backend-dev.firebaseapp.com',
  databaseURL: 'https://muva-backend-dev.firebaseio.com',
  projectId: 'muva-backend-dev',
  storageBucket: 'muva-backend-dev.appspot.com',
  messagingSenderId: '535024031644',
  appId: '1:535024031644:web:a444864250dd26bb',
};

if ((process.env.NODE_ENV === 'development' && !process.env.REACT_APP_STAGE) || process.env.REACT_APP_STAGE === 'qa'){
  firebase.initializeApp(devConfig);
  setLogLevel('silent');
}
else {
  firebase.initializeApp(firebaseConfig);
}

export const db = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();
export const auth = firebase.auth();

// connect to local emulator env
if (process.env.REACT_APP_EMULATE) {
  console.log('use emulators');
  db.useEmulator('localhost', 5002);
  functions.useEmulator('localhost', 5001);
  auth.useEmulator('http://localhost:5005');
}

const cloudFunc = (funcName, params) => functions.httpsCallable(funcName)(params);
const cloudData = (funcName, params) => cloudFunc(funcName, params).then(res => res.data);

/** Login */

const firstAuthStateChange = new Promise((resolve) => {
  auth.onAuthStateChanged(() => resolve());
});

export const isUserLoggedIn = async () => {
  await firstAuthStateChange;
  return !!firebase.auth().currentUser;
};

export const login = async (user, pass) => {
  await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
  await auth.signInWithEmailAndPassword(user, pass);
  const logInResponse= await firebase.auth().currentUser.getIdTokenResult();
  return logInResponse;
};

export const logout = () => auth.signOut()
  .then(() => {
    if (typeof window !== 'undefined' && window.location) 
      window.location.href = '/';
      ls.clear()
      removeLocalData('loginuser');
  })
  .catch(error => console.log(error));

export const userID = () => auth.currentUser.uid;

export const getChatRef = (chatID) =>
  firebase.firestore().collection('chats').doc(chatID);

export const loginWithCustomToken = async (token) => {
  try {
    return await auth.signInWithCustomToken(token);
  } catch (err) {
    throw err;
  }
}

export const simpleLogout = () => auth.signOut()

/** Video */

export const getVideoURL = async (fileName) => {
  try {
    const storageRef = storage.ref().child(fileName);
    const url = await storageRef.getDownloadURL();
    console.log(url);
    return url;
  } catch (error) {
    switch (error.code) {
      case 'storage/object-not-found':
        // File doesn't exist
        break;
      case 'storage/unauthorized':
        // User doesn't have permission to access the object
        break;

      case 'storage/canceled':
        // User canceled the upload
        break;
      case 'storage/unknown':
        // Unknown error occurred, inspect the server response
        break;
      default:
        break;
    }
  }
};

/** Direct DB Access */

export const getCustomers = async (setCustomers, moverId) => {
  // let query = db.collection('quotes')
  let queryToExe
  if (moverId) {
    const moverRef = await db.collection('movers').doc(moverId).get();
    const moverData = await moverRef.data();
    const {appID} = moverData;
    if (appID) 
      queryToExe = query(collection(db, 'quotes'), or(where('appID', '==', appID), where(`assigns.${moverId}.state`, '==', 'booked')));
     else 
     queryToExe = query(collection(db, 'quotes'), where(`assigns.${moverId}.state`, '==', 'booked'))
      // setCustomers([]);
      // return Promise.resolve(); 
  } else {
    queryToExe = query(collection(db, 'quotes'))
  }
  onSnapshot(queryToExe, async (ordersSnapshot) => {
    const orders = [];
    const promises = [];

    ordersSnapshot.forEach((doc) => {
      const order = doc.data();
      order.id = doc.id;
      orders.push(order);
    });

    for (const order of orders) {
      // so version 1 objects display
      if (!order.version) {
        order.version = 1;
        order.services = order.services ??  {};
        order.assigns = order.assigns ?? {};
        order.log = map(order.log, rec => ({...rec, at: rec.timestamp}));
      }

      const lastEle = last(order.log);
      order.lastModified = lastEle?.at?.seconds || 0;

      // get moverRef if relevant
      if (order.promoID) {
        const p = db.doc(`discounts/${order.promoID}`).get()
          .then(async doc => {
            const promo = doc.data();
            if (promo && promo.moverRef) {
              // order.moverRef = promo.moverRef;
              const mover =
                await db.doc(`movers/${promo.moverRef}`).get()
                  .then(doc => doc.data());
              
              if (mover) 
                order.moverRefName = mover.name;
            }
          });
        promises.push(p);
      }
    }
    
    await Promise.allSettled(promises);
    setCustomers(orders);
  });
};

export const getQuotes = async(setQuote ,loggedInData)=>{
  
  db.collection("quotes").onSnapshot(async (ordersSnapshot) => {
    const quotes = [];

    ordersSnapshot.forEach((doc) => {
      const order = doc.data();
      order.id = doc.id;
      quotes.push(order);
    });
  
    for (const order of quotes) {
      // so version 1 objects display
      if (!order.version) {
        order.version = 1;
        order.services = order.services ??  {};
        order.assigns = order.assigns ?? {};
        order.log = map(order.log, rec => ({...rec, at: rec.timestamp}));
      }

      const lastelm = last(order.log);
      order.lastModified = lastelm?.at?.seconds || 0;

    }

    let data = []
    let filteredObject = []

     quotes.map((item)=>{
      if(!isEmpty(item.assigns)) data.push(item);
      data?.map((item)=>{
        Object.keys(item.assigns).reduce((_, key) => {
          const nestedObject = item.assigns[key];
          if(!isEmpty(loggedInData))
            if (nestedObject?.proID === loggedInData?.mover) 
              filteredObject = [nestedObject]; 
          
        }, {});
      })
     })
  
    setQuote([...filteredObject])
  
  });
}

export const getOrderLinks = async (details) => {
  const linkIDs = {
    // regular links:
    ...details.links,
    // add in any links for assignments:
    // ..._.mapValues(details.assigns, assign => assign.linkID),
  };

  const links = {};
  await Promise.all(map(linkIDs,
    (linkID, key) => db.doc(`links/${linkID}`)
      .get()
      .then(doc => {
        const link = doc.data();
        links[key] = {
          ...link,
          id: linkID,
          expired: !link 
            || link.expired
            || (link.expiresAt && moment().isAfter(link.expiresAt.toMillis())),
        };
      }),
  ));

  return links;
};

export const getMovers = (setMovers) => {
  db.collection("movers").onSnapshot((snapshot) => {
    const movers = snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));
    setMovers(movers);
  });
};

export const getMoversWithWebApp = async () => {
  const querySnapshot = await getDocs(db.collection('apps'));
  const documents = querySnapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}))
  return documents;
}

export const getMover = async (id) => {
  let output;
  await db.collection("movers").doc(id).get().then((doc) => {
    output = doc.data();
  })
  return output;
};

export const getDiscounts = (setDiscounts) => {
  db.collection("discounts").onSnapshot(snapshot => {
    const discounts = snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));
    setDiscounts(discounts);
  });
};

export const getUser = async (userID) => {
  const userDoc = await db.collection('users').doc(userID).get();
  return userDoc.data();
};

/** Management API Functions */

export const createMover = (mover) => cloudFunc('pro-create', mover);
const updateFields = ['name', 'contact', 'email', 'phone', 'city', 'country', 
                      'address1', 'address2', 'state', 'zip', 'active',
                      'url', 'blurb', 'screenChat', 'info', 'appID', 'extraEmails'];
export const updateMover = async (proID, args) => {
  const update = {}
  updateFields.forEach(field => {
    if (!isUndefined(args[field])) update[field] = args[field];
  });
  return cloudFunc('pro-update', {proID, update});
};

export const uploadMoverLogo = async (file, moverId) => {
  if (!(file instanceof File)) return;
  try {
    const storageRef = ref(storage, `logos/${moverId}/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    await db.collection('movers').doc(moverId).update({logoUrl: downloadURL});
    return true;
  } catch (e) {
    return false;
  }
}

export const createMoverLogin = (proID) => cloudFunc('pro-createLogin', {proID});
export const getMoverPasswordLink = (proID) => cloudData('pro-getPasswordResetLink', {proID});
export const resetMoverPassword = (proID) => cloudFunc('pro-sendPasswordReset', {proID});
export const createSample = (proID) => cloudFunc('pro-createSample', {proID});
export const sendJustifiEmail = (proID) => cloudFunc('pro-sendJustifiEmail', {proID});
export const sendJustifiOnboarding = (prodID, args) => {
  return cloudFunc('justifi-onboard', {prodID, ...args})
}
export const getJustifiOnboardingLink = (proID) => cloudFunc('pro-getOnboardingLink', {proID});
export const getMoverDetailsForOnboarding = (proID) => cloudFunc('pro-getMoverDetailsForOnboarding', {proID});
export const updateJustifiBusinessData = (proID, bizID) => cloudFunc('pro-updateJustifiBusiness', {proID, bizID})

export const closeRequest = (orderID, state, notes) => cloudFunc('order-close', {orderID, state, notes});
export const assignMover = (orderID, proID, services, notes, urgent) => cloudFunc('order-assignPro', {orderID, proID, services, notes, urgent});
export const setDeadline = (orderID, proID, expiresAt) => cloudFunc('order-setExpiresAt', {orderID, proID, expiresAt});
export const adminReject = (orderID) => cloudFunc('order-adminReject', {orderID});
export const markTest = (orderID) => cloudFunc('order-markTest', {orderID});
export const seeQuote = (orderID) => cloudFunc('order-see', {orderID});

// args: {orderID, proID, type}
export const createDiscount = async (discount) => cloudFunc('promo-create', discount);
export const updateDiscount = async (discountID, update) => cloudFunc('promo-update', {discountID, update});
export const payoutQuote = async (orderID, serviceType) => cloudFunc('order-payout', {orderID, serviceType});
export const markCompleted = async (orderID) => cloudFunc('order-markCompleted', {orderID});
export const retryPayment = async (orderID) => cloudFunc('order-retryPayment', {orderID});
export const approveInvoice = async (orderID, serviceType) => cloudFunc('order-approveInvoice', {orderID, serviceType});
export const sendReviewRequest = async (orderID) => cloudFunc('order-sendReviewRequest', {orderID});
export const retryAuthorize = (linkID) => cloudFunc('order-retryAuthorize', {linkID});

export const toggleLinkExpired = (linkID) => cloudFunc('util-toggleLinkExpired', {linkID});
export const resendEmail = (args) => cloudFunc('util-resendEmail', args);

/** Dev API **/
export const resetQuoteToNew = (orderID) => cloudFunc('dev-resetQuoteToNew', {orderID});
export const minuteJob = () => cloudFunc('dev-minuteJob');
export const testExpireLinks = () => cloudFunc('dev-expireLinks');
export const doTimeShift = (orderID, millis) => cloudFunc('dev-doTimeShift', {orderID, millis});
export const testAuthorizePayments = () => cloudFunc('dev-authorizePayments');
export const testInvoiceMailer = () => cloudFunc('dev-invoiceMailer');
export const deleteQuote = orderID => cloudFunc('dev-deleteQuote', {orderID});
export const processStripeEvents = () => cloudFunc('dev-processStripeEvents');