import React, {useCallback, useEffect, useState} from 'react';
import {
  filterActiveQuotes,
  filterCompletedQuotes,
  filterExpiredQuotes,
  filterLiveTestQuotes,
  filterPresubmitQuote,
  filterRejectedQuotes,
} from "../../../util/status";
import {useRecoilValue, useSetRecoilState} from 'recoil';

import CustomerListing from "./Listing/CustomerListing";
import Filters from '../Detail/customer/Filters';
import FormControl from '@material-ui/core/FormControl';
import IndexFilter from "./IndexFilter";
import IndexHeader from "./IndexHeader";
import IndexTabs from "./IndexTabs";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import _ from 'lodash';
import {getMoversWithWebApp} from '../../../firebase';
import {makeStyles} from "@material-ui/styles";
import state from '../../../state';

const useStyles = makeStyles({
  customersIndex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '25px',
  },
  emptyText: {
    textAlign: 'center',
  },
  select: {
    maxWidth: '250px',
  },
});

function CustomersIndex({isMover = false} = {}) {
  const 
    styles = useStyles(),
    customers = useRecoilValue(state.customers.sorted),
    setCustomersSort = useSetRecoilState(state.customers.sort),
    [currentTab, setCurrentTab] = useState('ACTIVE'),

    [liveCustomers, testCustomers] = filterLiveTestQuotes(customers),
    presubmitCustomers = filterPresubmitQuote(liveCustomers),
    activeCustomers = filterActiveQuotes(liveCustomers),
    expiredCustomers = filterExpiredQuotes(liveCustomers),
    completedCustomers = filterCompletedQuotes(liveCustomers),
    rejectedCustomers = filterRejectedQuotes(liveCustomers);
    const {activeMovers} = useRecoilValue(state.movers.filtered);
  
  const [search, setSearch] = useState('');
  const [mover, setMover] = useState('');
  const [moversWithWebApp, setMoversWithWebApp] = useState([]);
  const [webApps, setWebApps] = useState([]);

  function handleChange(event, newValue) {
    setCurrentTab(newValue);
  }

  function handleSortCustomers(key, dir) {
    return () => setCustomersSort({key, dir});
  }

  const loadMoversWithWebApp = useCallback(async () => {
    const response = await getMoversWithWebApp();
    const moversIds = response.map((r) => r.proId);
    const moversWithWebAppFromResponse = activeMovers.filter((r) => moversIds.includes(r.id));
    setMoversWithWebApp(moversWithWebAppFromResponse);
    setWebApps(response);
  }, [activeMovers, setMoversWithWebApp, setWebApps])

  useEffect(() => {
    if (!isMover) loadMoversWithWebApp();
  }, [loadMoversWithWebApp, isMover]);

  const data = currentTab === 'PRESUBMIT' ? presubmitCustomers
  : currentTab === 'ACTIVE' ? activeCustomers 
  : currentTab === 'COMPLETED' ? completedCustomers
  : currentTab === 'EXPIRED' ? expiredCustomers
  : currentTab === 'REJECTED' ? rejectedCustomers
  : testCustomers;

  const filteredData = () => {
    let filteredArr = data;
    if (search.length) 
      filteredArr = filteredArr.filter((r) => _.get(r, 'contact.name', '').toLowerCase().includes(search.toLocaleLowerCase()))
    if (mover.length) {
      const selectedWebApp = webApps.find((r) => r.proId === mover);
      console.log({selectedWebApp})
      filteredArr = filteredArr.filter((r) => r.appID === selectedWebApp?.id);
    }
    return filteredArr;
  }

  const finalData = filteredData();

  return (
    <div className={styles.customersIndex}>
      <IndexHeader
        title={"Customer Quotes"}
      />
      {
        !isMover && <FormControl>
          <InputLabel id="custom-company-select-label">Web app</InputLabel>
          <Select
            className={styles.select}
            id="custom-company-select"
            labelId="custom-company-select-label"
            value={mover}
            onChange={(e) => setMover(e.target.value)}
            label="Custom"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {
              moversWithWebApp.map((r) => <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>)
            }
          </Select>
        </FormControl>
      }

      <IndexTabs
        currentTab={currentTab}
        handleChange={handleChange}
        tabs={[
          {value: "PRESUBMIT", label: `Presubmit - ${presubmitCustomers.length}`},
          {value: "ACTIVE", label: `Active - ${activeCustomers.length}`},
          {value: 'EXPIRED', label: `Expired - ${expiredCustomers.length}`},
          {value: "COMPLETED", label: `Completed - ${completedCustomers.length}`},
          {value: "REJECTED", label: `Rejected - ${rejectedCustomers.length}`},
          {value: "TEST", label: `Test - ${testCustomers.length}`},
        ]}
      />

      <IndexFilter
        filterButtons={
          [
            {label: "Name ASC", func: handleSortCustomers('contact.name', 'asc')},
            {label: "Name DESC", func: handleSortCustomers('contact.name', 'desc')},
            {label: "Status ASC", func: handleSortCustomers('state', 'asc')},
            {label: "Status DESC", func: handleSortCustomers('state', 'desc')},
            {label: "Modified ASC", func: handleSortCustomers('lastModified', 'asc')},
            {label: "Modified DESC", func: handleSortCustomers('lastModified', 'desc')},
          ]
        }
        custom={
          <Filters
            search={search}
            onSearch={(e) => setSearch(e.target.value)}
          />
        }
      />

      <CustomerListing
        data={finalData}
      />

      {
        finalData.length === 0 && <p className={styles.emptyText}>No matches for your search</p>
      }
    </div>
  );
}

export default CustomersIndex;
