import {useState} from 'react';

function useFileInput(inputRef) {
  const [file, setFile] = useState(null);

  const openFileDialog = () => {
    if (inputRef.current) 
      inputRef.current.click();
  };

  const onFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  return {
    file,
    inputRef,
    openFileDialog,
    onFileChange,
    setFile,
  };
}

export default useFileInput;
