import React, {useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {
  Select,
  TextField,
  InputLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Checkbox,
  Switch,
  Typography,
  Grid,
} from '@material-ui/core';
import {ActionDiv, ActionButton} from 'lib';
import {assignMover} from '../../../../firebase';
import ErrorDialog from "../../ErrorDialog";
import {AssignStates} from "../../../../util/status";

const useStyles = makeStyles({
  flexcol: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "25px",
    marginBottom: "5px",
    color: "#176BB5",
  },
  info: {
    fontSize: "12px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
  },
  formLine: {
    marginTop: '20px',
  },
  submitContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  switchLabel: {
    fontSize: '12px',
  },
});

const AssignMoverForm = ({pros, details, collapse, onClose}) => {
  const styles = useStyles();
  const {assigns} = details;
  const availPros = pros.filter(pro => {
    const assign = assigns[pro.id];
    return !assign || assign.state !== AssignStates.assigned
  });
  const [errorMsg, setErrorMsg] = useState('');
  const [uploading, setUploading] = useState(false);
  const [inputs, setInputs] = useState({
    proID: details.proID || details.moverRef,
    // services:
    move: details.defaultServices?.includes('move'),
    storage: details.defaultServices?.includes('storage'),
    moveIn: details.defaultServices?.includes('moveIn'),

    urgent: false,
    notes: details.adminNotes || '',
  });
  const services = ['move', 'storage', 'moveIn'].map(s => inputs[s] && s).filter(s => !!s);
  const isFormComplete = !!inputs.proID && !!services.length;

  const handleInputChange = name => event => {
    if (event.persist) {
      event.persist();
    }
    setInputs(({...inputs, [name]: event.target.value}));
  };
  const handleInputSwitch = name => event => {
    setInputs({...inputs, [name]: event.target.checked});
  };
  const handleSubmitClick = () => {
    if (uploading) return;
    setUploading(true);
    return assignMover(details.id, inputs.proID, services, inputs.notes, inputs.urgent)
      .finally(() => setUploading(false))
      .then(() => onClose(false))
      .catch(err => setErrorMsg(err.message))
  };

  return (
    <ActionDiv collapse={collapse} onClose={onClose}>
      <div className={styles.flexcol}>
        <span className={styles.title}>Select Mover</span>
        <div className={styles.info}>
          Please select the mover you'd like to send this quote request to.
          If you'd like to include an extra message in the email you can do so below.
        </div>
      </div>
      <div className={styles.form}>
        <FormControl>
          <InputLabel htmlFor="pro-select" shrink>Mover</InputLabel>
          <Select
            value={inputs.proID || ''}
            onChange={handleInputChange('proID')}
            inputProps={{
              name: 'Mover',
              id: 'pro-select',
            }}>
            {availPros.map(pro => {
                return <MenuItem value={pro.id} key={pro.id}>{pro.name}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl className={styles.formLine} component="fieldset" >
          <FormHelperText>Services</FormHelperText>
          <FormGroup>
            <Grid container>
              <Grid>
                <FormControlLabel
                  control={<Checkbox checked={inputs.move} onChange={handleInputSwitch('move')} name="move" />}
                  label="Move"/>
              </Grid>
              <Grid>
                <FormControlLabel
                  control={<Checkbox checked={inputs.storage} onChange={handleInputSwitch('storage')} name="storage" />}
                  label="Storage"/>
              </Grid>
              <Grid>
                <FormControlLabel
                  control={<Checkbox checked={inputs.moveIn} onChange={handleInputSwitch('moveIn')} name="moveIn" />}
                  label="Move In"/>
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
        <FormControl className={styles.formLine}>
          <FormHelperText>Urgent</FormHelperText>
          <FormControlLabel
            control={
              <Switch
                checked={inputs.urgent}
                onChange={handleInputSwitch('urgent')}
                value="urgent"
                size="small"
                color="primary"
              />
            }
            label={
              <Typography className={styles.switchLabel}>
                Urgent Request (2hr)
              </Typography>
            }
          />
        </FormControl>
        <TextField
          className={styles.formLine}
          label="Admin Notes"
          value={inputs.notes}
          InputLabelProps={{shrink: true}}
          id="notes"
          multiline={true}
          rows={4}
          onChange={handleInputChange("notes")}
        />
        <div className={styles.submitContainer}>
          <ActionButton 
            onClick={handleSubmitClick} 
            disabled={!isFormComplete}
            loading={uploading}
          >
            Send Quote Request
          </ActionButton>
        </div>
        <ErrorDialog
          open={!!errorMsg}
          handleClose={() => setErrorMsg('')}
          title="Uh oh! Something Went Wrong."
          message={errorMsg}
        />
      </div>
    </ActionDiv>
  );
}

export default AssignMoverForm;
