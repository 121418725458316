import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

const OverlapDiv = ({children}) => {
  const cn = useStyles();
  return (
    <div className={cn.root}>
      {children}
    </div>
  );
};

OverlapDiv.propTypes = {
  children: PropTypes.any.isRequired,
};

export default OverlapDiv;
