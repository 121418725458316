import React from "react";
import {useRecoilValue} from "recoil";
import {Paper, Table, TableBody, TableCell, TableContainer, TableRow} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";
import state from '../../../../state';
import {getQuoteStateText, getStateText} from '../../../../util/status';
import * as util from '../../../../util/util';

const DetailHistory = ({details}) => {
  const cn = useStyles();
  const movers = useRecoilValue(state.movers.movers);
  const {log, version} = details;

  /// deprecated
  if (version === 1) return (
    <Paper elevation={1} className={cn.historyInfo}>
      <div className={cn.infoTitle}>Quote Action History</div>
      {
        log.map((record, i) => {
          const {quotePrice, finalPrice} = record;
          const isEven = i % 2 === 1;
          const action 
            = version < 2 ? getQuoteStateText(record.action, false)
            : getStateText(record.action);
          const time = util.time(record.at).format("h:mm a - MMM Do, YYYY");
          const moverName = record.proID && movers.find(m => m.id === record.proID).name
          return (
            <div 
              className={cn.record + ' ' + (isEven && cn.even)}
              key={`history-record-${i}`}
            >
              <span className={cn.padRow}>{action}</span>
              { moverName && <span className={cn.padRow}>{moverName}</span> }
              { quotePrice && <span className={cn.padRow}>{`$${quotePrice.min/100} - $${quotePrice.max/100}`}</span> }
              { finalPrice && (<>
                <span className={cn.padRow}>{`$${finalPrice.mover}`}</span>
                { finalPrice.additionalCharges && <span className={cn.padRow}>{`Additional charges requested`}</span>}
              </>)}
              <span>{time}</span>
            </div>
          );
        })
      }
    </Paper>
  );

  else return (
    <Paper elevation={1} className={cn.historyInfo}>
      <div className={cn.infoTitle}>Quote Action History</div>
      <TableContainer>
        <Table className={cn.table} size="small" aria-label="a dense table">
          <TableBody>
          {log.map((record, i) => {
            const isEven = i % 2 === 1;
            const action = getStateText(record.action);
            const recordinfo
              = (record.data?.proID && movers.find(m => m.id === record.data.proID).name)
              || record.data?.serviceType 
              || (record.data?.maxPrice && `${util.$tr(record.data?.maxPrice)}`)
              || '';
            const hash = util.hashCode(JSON.stringify(record));
            return (
              <TableRow className={isEven ? cn.even : null} key={hash}>
                <TableCell component="th" scope="row">
                  {util.shortDateStr(record.at)} {util.timeStr(record.at)}
                   </TableCell>
                <TableCell >{action}</TableCell>
                <TableCell align="right">{recordinfo ? util.capitalize(recordinfo): ''}</TableCell>
              </TableRow>
            );
          })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const useStyles = makeStyles({
  historyInfo: {
    padding: "20px 20px 20px 20px",
    minWidth: 450,
  },
  infoTitle: {
    fontSize: 30,
  },
  record: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 15px 15px 5px',
    color: '#757575',
  },
  even: {
    background: '#f7f5f5',
  },
  padRow: {
    paddingBottom: '5px',
  },
});

export default DetailHistory;
