import React,{useEffect} from 'react';
import QuoteIndex from './Index/QuoteIndex';
import {useSetRecoilState, useRecoilValue} from 'recoil';
import state from '../../state';
import {getQuotes} from '../../firebase';
import Loading from 'components/Loading';


function QuotePanel() {

  const setQuote = useSetRecoilState(state.quotes.quotes)
  const quotLoaded = useRecoilValue(state.quotes.loaded);
  const loggedInData = useRecoilValue(state.app.loggedInData);

  

  useEffect(()=>{
    if(!quotLoaded) getQuotes(setQuote ,loggedInData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    (
      !quotLoaded ? <Loading/>:   <QuoteIndex />
    )
  );
}

export default QuotePanel;
