// deprecated for version 2+

import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/styles";
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import tz from 'moment-timezone';

import DetailLine from "../../DetailLine";
import {getMover} from "../../../../../firebase";

const useStyles = makeStyles({
  quoteInfo: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  infoTitle: {
    fontSize: '30px',
  },
  infoSection: {
    marginTop: '30px',
  },
});

function QuoteInfo(props) {
  const styles = useStyles();
  const [mover, setMover] = useState({});

  const quote = props.details.quote;

  useEffect(() => {
    async function getMoverData() {
      const mover = await getMover(props.details.proID);
      setMover(mover);
    }
    if (props.details.proID && !mover.name) getMoverData();
  });

  return (
    <div className={styles.quoteInfo}>
      <span className={styles.infoTitle}>Quote From {mover.name}</span>
      <div className={styles.infoSection}>
        <DetailLine
          label="Max Price"
          values={[
            `Consumer: $${quote.price.max/100}`,
            `Mover: $${quote.price.mover.max/100}`,
          ]}
        />
        <DetailLine
          label="Min Price"
          values={[
            `Consumer: $${quote.price.min/100}`,
            `Mover: $${quote.price.mover.min/100}`,
          ]}
        />
        <DetailLine
          label="Deposit"
          values={[`$${quote.deposit/100}`]}
        />
        <DetailLine
          label="Move Date"
          values={[
            quote.moveDateTime ? moment(quote.moveDateTime.toMillis()).tz('America/Los_Angeles').format('MMM Do, YYYY') : null,
          ]}
        />
        <DetailLine
          label="Move Time"
          values={[
            quote.moveDateTime ? moment(quote.moveDateTime.toMillis()).tz('America/Los_Angeles').format('h:mm a z'): null,
          ]}
        />
        <DetailLine label="Mover Notes" values={[ quote.notes ]} />
        <DetailLine label="Number of Movers" values={[ quote.numMovers ]} />
        <DetailLine label="Number of Trucks" values={[ quote.numTrucks ]} />
      </div>
    </div>
  );
}

export default QuoteInfo;
