import _ from 'lodash';
import React from "react";
import {Paper} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";
import DetailLine from "../DetailLine";

const FeedbackInfo = ({details}) => {
  const cn = useStyles();

  const feedbackQuestions = {
    moverOnTime: 'Was your assigned mover on time, on the day of your move?',
    moverPreparation: 'How well prepared was your mover?',
    moverTransport: 'How well did your mover handle and transport your belongings?',
    moverSecurelyWrap: 'Were your belongings securely wrapped and transported by the mover?',
    moverDeliverOnTime: 'Did your mover pack and deliver your goods, in a time efficient manner?',
    muvaEaseOfUse: 'How easy to use did you find the Muva app in making your booking?',
    muvaOverall: 'Please describe your overall Muva experience:',
  };

  const feedbacks = [];
  if (details.feedback) // before multiple service feedback
    feedbacks.push([details.feedback]);
  if (details.services)
    _.map(details.services, (s, key) => {
      if (!s.feedback) return;
      feedbacks.push([s.feedback, key]);
    });

  if (!feedbacks.length)
    return null;

  return (
    <Paper elevation={1} className={cn.linkInfo}>
      <div className={cn.infoTitle}>Feedback</div>
        {feedbacks.map(([feedback, key], i) => (
          <div className={cn.infoSection}>
            {key && <div className={cn.subTitle}>{key}</div>}
            {_.map(feedbackQuestions, (question, key) => 
              <DetailLine
                key={key}
                label={question}
                values={[`${feedback[key]?.value} -- ${feedback[key]?.comment}`]}
                long
              />,
            )}
          </div>
        ))}
    </Paper>
  );
};

const useStyles = makeStyles({
  linkInfo: {
    marginTop: 30,
    marginBottom: 30,
    padding: '20px 20px 20px 20px',
    minWidth: 450,
  },
  infoTitle: {
    fontSize: 30,
  },
  infoSection: {
    marginTop: 15,
    borderTop: 'solid 1px rgba(224, 224, 224, 1)',
    paddingTop: 15,
  },
  subTitle: {
    marginBottom: 8,
    fontSize: 20,
  },
});

export default FeedbackInfo;
