import React, {useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {makeStyles} from "@material-ui/styles";
import {LibraryAdd, Close} from '@material-ui/icons';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import OutlinedInput from '@material-ui/core/OutlinedInput';

import state from '../../../state';
import IndexHeader from "./IndexHeader";
import IndexTabs from "./IndexTabs";
import IndexFilter from "./IndexFilter";
import MoverListing from "./Listing/MoverListing";
import MoverForm from '../MoverForm';

const useStyles = makeStyles({
  moversIndex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '25px',
  },
  addButton: {
    background: 'white',
  },
  buttonLabel: {
    marginRight: '15px',
    fontSize: '12px',
  },
  createFormArea: {
    padding: '15px 25px',
    width: '400px',
  },
  closeForm: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  confirmationPopup: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
  },
  confirmMessage: {
    marginBottom: '20px',
  },
  emptyText: {
    textAlign: 'center',
  },
});

function MoversIndex() {
  const styles = useStyles();
  const {activeMovers, inactiveMovers} 
    = useRecoilValue(state.movers.filtered);
  const setMoversSort = useSetRecoilState(state.movers.sort)

  const [currentTab, setCurrentTab] = useState('ACTIVE');
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [search, setSearch] = useState('');

  const onTabChange = (event, newValue) => setCurrentTab(newValue);
  const genOnSort = (key, dir) => () => setMoversSort({key, dir});
  const onCreateSuccess = () => {
    setModalOpen(false);
    setConfirmationModal(true);
  };

  const data = currentTab === "ACTIVE" ? activeMovers : inactiveMovers
  
  const filteredData = search.length ? data.filter((r) => r.name.toLowerCase().includes(search.toLocaleLowerCase())) : data;

  return (
    <div className={styles.moversIndex}>
      <IndexHeader
        title={"Moving Companies"}
        toolbar={
          <Button
            classes={{root: styles.addButton}}
            variant="contained"
            onClick={() => setModalOpen(true)}
          >
            <span className={styles.buttonLabel}>New Mover</span>
            <LibraryAdd htmlColor="#2085c9" />
          </Button>
        }
      />

      <IndexTabs
        currentTab={currentTab}
        handleChange={onTabChange}
        tabs={[
          {value: "ACTIVE", label: `Active - ${activeMovers.length}`},
          {value: "INACTIVE", label: `Inactive - ${inactiveMovers.length}`},
        ]}
      />

      <IndexFilter
        filterButtons={
          [
            {label: "Name ASC", func: genOnSort('name', 'asc')},
            {label: "Name DESC", func: genOnSort('name', 'desc')},
            {label: "Moves ASC", func: genOnSort('movesComplete', 'asc')},
            {label: "Moves DESC", func: genOnSort('movesComplete', 'desc')},
          ]
        }
        custom={
          <OutlinedInput
            type="search"
            margin="dense"
            placeholder="Search..."
            className={styles.searchInput}
            value={search} 
            onChange={(e) => setSearch(e.target.value)}
          />
        }
      />

      <MoverListing
        data={filteredData}
      />
      {
        filteredData.length === 0 && <p className={styles.emptyText}>No matches for your search</p>
      }
      <Dialog open={modalOpen}>
        <div className={styles.createFormArea}>
          <div className={styles.closeForm}>
            <Close
              htmlColor="#949494"
              className={styles.closeIcon}
              onClick={() => setModalOpen(false)}
            />
          </div>
          <MoverForm 
            mover={{name: "", phone: "", email: "", state: ""}}
            onSuccess={onCreateSuccess}
            create
          />
        </div>
      </Dialog>
      <Dialog open={confirmationModal}>
        <div className={styles.confirmationPopup}>
          <span className={styles.confirmMessage}>Mover has been created</span>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setConfirmationModal(false)}
          >
            Got it
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default MoversIndex;
