import {atom, selector} from 'recoil';
import {sortedObjectsBy} from '../util/sorting';

export const genSortedDocs = (key) => {
  const docs = atom({
    key: `${key}/${key}`,
    default: undefined,
    // need for firebase in dev mode:
    dangerouslyAllowMutability: true,
  });
  const loaded = selector({
    key: `${key}/loaded`, 
    get: ({get}) => Array.isArray(get(docs)),
    dangerouslyAllowMutability: true,
  });
  const sort = atom({
    key: `${key}/sort`,
    default: {key: 'lastModified', dir: 'desc'},
    dangerouslyAllowMutability: true,
  });
  const sorted = selector({
    key: `${key}/sorted`,
    get: ({get}) => {
      const res = sortedObjectsBy(
        get(docs), get(sort).key, get(sort).dir,
      )
      return res;
    },
    dangerouslyAllowMutability: true,
  });

  const detailsID = atom({
    key: `${key}/detailID`,
    default: null,
    dangerouslyAllowMutability: true,
  });
  const details = selector({
    key: `${key}/detail`,
    get: ({get}) => get(docs).find(d => d.id === get(detailsID)),
    dangerouslyAllowMutability: true,
  });
  const isDetailsOpen = selector({
    key: `${key}/isDetailOpen`,
    get: ({get}) => !!get(detailsID),
    dangerouslyAllowMutability: true,
  });

  return {docs,loaded,sort,sorted, details, detailsID, isDetailsOpen};
};