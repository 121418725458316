import {useCallback, useEffect, useState} from 'react';

import firebase from 'firebase/compat/app';

export function useClaims() {
    const [claims, setClaims] = useState({});
    const [loadingClaims, setLoadingClaims] = useState(true);

    const getClaims = useCallback(async () => {
        setLoadingClaims(true);
        const result = await firebase.auth().currentUser.getIdTokenResult();
        setClaims(result.claims);
        setLoadingClaims(false);
    }, [setClaims, setLoadingClaims]);

    const isAdmin = Boolean(claims.admin);
    const isMover = Boolean(claims.mover) && !isAdmin;

    useEffect(() => {
        getClaims();
    }, [getClaims]);

    return {
        claims,
        isMover,
        isAdmin,
        loadingClaims,
        moverId: claims?.mover || '',
    }
}