import React, {useEffect} from 'react';
import {useRecoilState} from 'recoil';
import {Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import state from './state';
import {isUserLoggedIn, auth, login} from './firebase';
import {Login} from 'lib';
import Dashboard from './components/Dashboard/Dashboard';
import {getLocalData} from 'util/localstorage';

function Main() {
  const cn = useStyles();
  const [loggedIn, setLoggedIn] = useRecoilState(state.app.loggedIn);

  // eslint-disable-next-line
  const [,setLoggedInData] = useRecoilState(state.app.loggedInData)
  useEffect(() => {
    isUserLoggedIn().then(res =>{
      setLoggedIn(res)});
  }, [setLoggedIn]);

  useEffect(()=>{
     getLocalData('loginuser') && setLoggedInData(getLocalData('loginuser'));
       // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return loggedIn == null 
    ? <div/>
    : !loggedIn 
      ? <div className={cn.page}>
          <Paper className={cn.loginForm} elevation={3}>
            <Login auth={auth} login={login} onLogin={setLoggedIn} loggedInDataFunc={setLoggedInData}/>
          </Paper>
        </div>
      : <Dashboard />
}

const useStyles = makeStyles({
  page: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginForm: {
    height: 350,
    width: 400,
  },
});

export default Main;
