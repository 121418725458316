import {
  CircularProgress,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";

import Badge from "components/Badge";
import Button from "@material-ui/core/Button";
import React from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
  leftText: {
    width: "33%",
    whiteSpace: "pre",
  },
  middleText: {
    width: "33%",
    whiteSpace: "pre",
  },
  rightText: {
    width: "33%",
    whiteSpace: "pre",
  },
  spinner: {
    marginLeft: 7,
  },
});

function PanelFilter({
  leftPrimary,
  leftSecondary,
  middlePrimary,
  middleSecondary,
  rightPrimary,
  rightSecondary,
  buttonAction,
  data,
  buttonDisable,
  loading,
  buttonLabel,
  badgeField = '',
}) {
  const styles = useStyles();
  return (
    <ListItem disableGutters={true}>
      <ListItemText
        className={styles.leftText}
        primary={
          <div>
            {leftPrimary} {badgeField && <Badge text={data[badgeField]} />}
          </div>
        }
        secondary={leftSecondary}
      />

      {middlePrimary || middleSecondary ? (
        <ListItemText
          className={styles.middleText}
          primary={middlePrimary}
          secondary={middleSecondary}
        />
      ) : (
        <div className={styles.middleText} />
      )}

      <ListItemText
        className={styles.rightText}
        primary={rightPrimary}
        secondary={rightSecondary}
      />

      <ListItemSecondaryAction>
        <Button
          variant="outlined"
          color="default"
          onClick={() => buttonAction(data)}
          disabled={buttonDisable || loading}
          disableRipple={buttonDisable}
        >
          {buttonLabel}
          {loading && <CircularProgress size={20} className={styles.spinner} />}
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default PanelFilter;
