import {$tr, capitalize, shortDateStr, time} from '../../../../util/util';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from '@material-ui/core';

import {Info} from '@material-ui/icons';
import {Paper} from '@material-ui/core';
import React from "react";
import _ from 'lodash';
// datagrid can't currently fill parents divs 
// import {DataGrid} from '@material-ui/data-grid';
import {makeStyles} from "@material-ui/styles";

const AccountTableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>Date</TableCell>
      <TableCell align="Center">Service</TableCell>
      <TableCell align="Center">Type</TableCell>
     <TableCell align="Center">Payment Info</TableCell>
      <TableCell align="right">Amount</TableCell>
    </TableRow>
  </TableHead>
);

const AccountTable = ({children, className}) => (
  <TableContainer>
    <Table className={className} size="small" aria-label="account">
      <AccountTableHeader />
      <TableBody>
        {children}
      </TableBody>
    </Table>
  </TableContainer>
)

function AccountInfo({details: {account}}) {
  const cn = useStyles();

  const sortFn = (a,b) => time(a.txnAt).diff(time(b.txnAt));
  const charges = _.map(account.pending).concat(_.map(account.charges)).sort(sortFn);
  const authorizes = _.map(account.authorizes).sort(sortFn);
  const payouts = _.map(account.payouts).sort(sortFn);

  const getPaymentLabel = (paymentDetails) => {
    if (!paymentDetails) return 'N/A';
    const {type, card, usBankAccount} = paymentDetails.details || {};
    if (type === 'card') 
      return  <span className={cn.flexCenter}>Card <Tooltip title={`${capitalize(card.brand)}: ${card.last4}`}><Info fontSize='small'/></Tooltip></span>
    else if (type === 'us_bank_account')
      return  <span className={cn.flexCenter}>ACH <Tooltip title={`${capitalize(usBankAccount.bankName)}: ${usBankAccount.last4}`}><Info fontSize='small'/></Tooltip></span>
    return `${type || '--'}`
  }

  if (!authorizes.length 
      && !charges.length 
      && !payouts.length)
    return null;

  return (
    <Paper elevation={1} className={cn.accountInfo}>
      <span className={cn.infoTitle}>Account</span>
      <div className={cn.infoSection}>
        {/* Auths Table */}
        <div className={cn.tableTitle}>Authorizes</div>
        <AccountTable className={cn.table}>
          {authorizes.map((row) => (
            <TableRow key={row.id} hover>
              <TableCell component="th" scope="row">{shortDateStr(row.txnAt)}</TableCell>
              <TableCell align="center">{capitalize(row.serviceType)}</TableCell>
              <TableCell align="center">{capitalize(row.type)}</TableCell>
              <TableCell align="right">
                {$tr(row.amount) + (row.captured ? '  ✓' : row.expired ? ' x' : '')}
              </TableCell>
            </TableRow>
          ))}
        </AccountTable>
        {/* Charges Table */}
        <div className={cn.tableTitle}>Charges</div>
        <AccountTable className={cn.table}>
          {charges.map((row) => (
            <TableRow key={row.id} hover>
              <TableCell component="th" scope="row">{shortDateStr(row.txnAt)}</TableCell>
              <TableCell align="center">{capitalize(row.serviceType)}</TableCell>
              <TableCell align="center">{capitalize(row.type)}</TableCell>
              <TableCell align="center">{getPaymentLabel(row.paymentDetails)}</TableCell>
              <TableCell align="right">
                {$tr(row.amount) + (row.available ? '  ✓' : row.pending ? ' ↻' : row.failed ? ' x' : '')}
              </TableCell>
            </TableRow>
          ))}
        </AccountTable>
        {/* Account Table */}
        <div className={cn.tableTitle}>Payouts</div>
        <AccountTable className={cn.table}>
          {payouts.map((row) => (
            <TableRow key={row.id} hover>
              <TableCell component="th" scope="row">{shortDateStr(row.txnAt)}</TableCell>
              <TableCell align="center">{capitalize(row.serviceType)}</TableCell>
              <TableCell align="center">{row.type === 'pro' ? 'Mover' : 'Muva'}</TableCell>
              <TableCell align="right">{$tr(row.amount) + (row.paid ? '  ✓' : '')}</TableCell>
            </TableRow>
          ))}
         </AccountTable>
        {/* <DataGrid
          showToolbar={false}
          autoHeight={true}
          hideFooter={true}
          density={'compact'}
          rows={charges} 
          columns={[
            {
              width: 100,
              field: 'timestamp',
              headerName: 'Date',
              valueFormatter: (params) => util.shortTimeStr(params.value),
            },
            {
              width: 100,
              field: 'serviceType',
              headerName: 'Service',
              valueFormatter: (params) => util.capitalize(params.value),
            },
            {
              width: 100,
              field: 'type',
              headerName: 'Type',
              valueFormatter: (params) => util.capitalize(params.value),
            },
            {
              width: 110,
              field: 'amount',
              headerName: 'Amount',
              valueFormatter: (params) => util.$tr(params.value) + (params.getValue('available') ? '  ✓' : ''),
            },
            // {field: 'lastName', headerName: 'Last name', width: 130},
            // {field: 'age', headerName: 'Age', type: 'number', width: 90},
            // {
            //   field: 'fullName',
            //   headerName: 'Full name',
            //   description: 'This column has a value getter and is not sortable.',
            //   sortable: false,
            //   width: 160,
            //   valueGetter: (params) =>
            //     `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
            // },
          ]}
          pageSize={100}
        /> */}
      </div>
    </Paper>
  );
}

const useStyles = makeStyles({
  accountInfo: {
    marginTop: 30,
    marginBottom: 30,
    padding: '20px 20px 20px 20px',
    minWidth: 450,
  },
  infoTitle: {
    fontSize: 30,
  },
  infoSection: {},
  tableTitle: {
    marginTop: 20,
    fontSize: 20,
    marginBottom: 8,
  },
  table: {
    border: '1px solid #E0E0E0',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default AccountInfo;
