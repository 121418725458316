
import {genSortedDocs} from './generic';

const {
  docs: quotes,loaded,details,
} = genSortedDocs('quotes');



export default {
    quotes,loaded,details,
};