import _ from 'lodash';
import moment from 'moment';

import React, {useState, useRef, useEffect} from 'react';
import {TextField, IconButton, Typography, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {red, green} from '@material-ui/core/colors'

import {
  Send as SendIcon,
  CheckCircleOutlineOutlined as CheckIcon,
  Block as BlockIcon,
} from '@material-ui/icons';

// detects firefox:
// https://stackoverflow.com/questions/49328382/browser-detection-in-reactjs
// necessary cause firefox flexbox column-reverse breaks scrolling
// https://bugzilla.mozilla.org/show_bug.cgi?id=1042151
// TODO: apparently this is fixed now, to be removed
const isFirefox = typeof InstallTrigger !== 'undefined';

const LOAD_EARLIER_THRESHOLD = 5;
const WATCH_SIZE = 20;
const LOAD_EARLIER_SIZE = 40;

const redTheme = createTheme({palette: {primary: red}});
const greenTheme = createTheme({palette: {primary: green}});

const sendMsgFn = (functions, chatID, message) => 
  functions.httpsCallable('chat-send')({chatID, message});
const sendReviewFn = (functions, chatID, msgID, approved) => 
  functions.httpsCallable('chat-review')({chatID, msgID, approved});

const Message = ({cn, userID, admin, functions, chatID, curMsg, nextMsg}) => {
  const isUser = curMsg.userID === userID;
  const [showTime, setShowTime] = useState(false);
  const [loading, setLoading] = useState(false);

  const onMouseEnter = () => setShowTime(true);
  const onMouseLeave = () => setShowTime(false);

  const onReview = (approved) =>
    () => {
      setLoading(true);
      sendReviewFn(functions, chatID, curMsg.id, approved)
        .catch(err => alert(`Failed to submit review: ${err.message}`))
        .finally(() => setLoading(false));
    };

  const renderDate = () => (
    <div className={cn.dateContainer}>
      <Typography className={cn.dateText} variant="caption">
        {curMsg.moment.format('dddd, MMM Do')}
      </Typography>
    </div>
  );
  const renderName = () => (
    <div className={isUser ? cn.userMsgItemContainer : cn.otherMsgItemContainer}>
      <Typography className={cn.nameText} variant="caption">{curMsg.name}</Typography>
    </div>
  );
  const renderTimestamp = () => (
    <Typography 
      className={showTime ? cn.timeText : cn.timeTextHidden} 
      variant="caption">
      {curMsg.moment.format('h:mma').slice(0, -1)}
    </Typography>
  );

  return (
    <div 
      className={`${isUser ? cn.userRow : cn.otherRow} ${admin && curMsg.rejected ? cn.rejectedRow: ''}`} 
      key={curMsg.id}
      onMouseEnter={onMouseEnter} 
      onMouseLeave={onMouseLeave}>
      {(!nextMsg || !curMsg.moment.isSame(nextMsg.moment, 'day')) && renderDate()}
      {(!nextMsg || curMsg.userID !== nextMsg.userID) && renderName()}
      <div className={isUser ? cn.userMsgItemContainer : cn.otherMsgItemContainer}>
        {isUser && renderTimestamp()}
        <div className={isUser ? cn.userBubble : cn.otherBubble}>
          <Typography className={cn.messageText} variant="body1">{curMsg.text}</Typography>
        </div>
        {!isUser && renderTimestamp()}
      </div>
      {admin && curMsg.pending && !curMsg.rejected && <div>
        <MuiThemeProvider theme={greenTheme}>
          <IconButton 
            className={cn.reviewButton}
            variant="outlined"
            color="primary"
            disabled={loading}
            onAnimationEnd={onReview(true)}
            children={<CheckIcon/>} />
        </MuiThemeProvider>
        <MuiThemeProvider theme={redTheme}>
          <IconButton 
            className={cn.reviewButton}
            variant="outlined"
            color="primary"
            disabled={loading}
            onAnimationEnd={onReview(false)}
            children={<BlockIcon/>} />
        </MuiThemeProvider>
      </div>}
    </div>
  );
}

// need to re-implement: onSend, onLoadEarlier?
const Chat = ({chatRef, functions, userID, admin, hidden}) => {
  const 
    cn = useStyles(),
    chat = useRef({names: []}),
    unsub = useRef({chat: null, messages: null, messagesPending: null}),
    closing = useRef(false),
    [loadCnt, setLoadCnt] = useState(0),
    loading = loadCnt < 3, // expect 3 callbacks to finish before display
    [hasEarlier, setHasEarlier] = useState(true),
    [messages, setMessages] = useState([]),
    [lastMsgAt, setLastMsgAt] = useState(0),
    [inputMessage, setInputMessage] = useState(''),
    [loadingEarlier, setLoadingEarlier] = useState(false),
    scrollDivRef = useRef(null);

  /** chat loading code */
  const dbToLocal = message => {
    return {
      ...message,
      name: chat.current.names[message.userID],
      moment: moment(message.timestamp.toMillis()),
    };
  };
  const onChatError = err => alert(`Chat loading failure: ${err.message}`)
  const openChat = async () => {
    console.log(`setting up chat loader for ${chatRef.id}`);
  
    const onChat = doc => {
      chat.current = doc.data();
      setLoadCnt(cur => cur + 1);
    };
    unsub.current.chat = chatRef.onSnapshot(onChat, onChatError);

    const onMessage = (pending = false) => 
      query => {
        // add in newest first, oldest last
        query.docChanges().reverse().forEach(change => {
          if (change.type === 'removed') return; // ignore removals
          const msg = dbToLocal(change.doc.data());
          // set whether message is pending or not
          msg.pending = pending;
          
          setMessages(msgs => {
            const found = msgs.find(m => m.id === msg.id);
            // new messages are added
            if (!found) {
              return [msg, ...msgs].sort(
                (a, b) => b.timestamp.toMillis() - a.timestamp.toMillis(),
              );
            // found messages are updated,
            // happens when message changes from pending->accepted
            } else {
              Object.assign(found, msg);
              return [...msgs];
            }
          });
        });
        setLoadCnt(cur => cur + 1);
      };
  
    // listen for changes in messages
    const chatMessages = chatRef.collection('messages');
    unsub.current.messages 
      = chatMessages.orderBy('timestamp', 'desc').limit(WATCH_SIZE)
        .onSnapshot(onMessage(), onChatError);
    
    // listen for changes in pending messages
    const chatMessagePending = chatRef.collection('messagesPending');
    unsub.current.messagesPending 
      = chatMessagePending.orderBy('timestamp', 'desc')
        .onSnapshot(onMessage(true), onChatError);
  };
  const closeChat = () => {
    closing.current = true;
    if (unsub.current.chat) unsub.current.chat();
    if (unsub.current.messages) unsub.current.messages();
    if (unsub.current.messagesPending) unsub.current.messagesPending();
  };
  const loadEarlier = () => {
    const onEarlierMsgs = query => {
      setMessages(msgs =>
        msgs.concat(query.docs
          .map(doc => dbToLocal(doc.data()))
          .filter(msg => !msgs.some(m => m.id === msg.id)),
        ),
      );
      // return if more messages or not
      return !(query.size < LOAD_EARLIER_SIZE)
    }

    return chatRef.collection('messages')
      .orderBy('timestamp', 'desc')
      // startAt, not after, because of edge case where two messages have the same timestamp
      //  -- always grabs an excess message, so we will always filter
      .startAt(_.last(messages).timestamp)
      .limit(LOAD_EARLIER_SIZE)
      .get()
      .then(onEarlierMsgs)
      .catch(onChatError)
  };
  useEffect(() => {
    openChat();
    return closeChat;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // loadEarlier if scroll reaches top
  const onScroll = ev => {
    if (!hasEarlier || loadingEarlier) return;
    const div = ev.target;
    if (isFirefox ? (div.scrollHeight - div.scrollTop - div.clientHeight <= LOAD_EARLIER_THRESHOLD)
        : div.scrollHeight + div.scrollTop - div.clientHeight <= LOAD_EARLIER_THRESHOLD)  {
      setLoadingEarlier(true);
      loadEarlier()
        .then(hasEarlier => setHasEarlier(hasEarlier || false))
        .catch(err => alert(`Fail to load messages: ${err.message}`))
        .finally(() => setLoadingEarlier(false));
    }
  };

  const onClickSend = () => {
    if (!inputMessage.trim()) return;
    const now = Date.now();
    const msg = {
      id: crypto.randomUUID(),
      text: inputMessage,
      userID: userID,
      timestamp: {toMillis: () => now.valueOf()},
      moment: moment(now),
      sent: false,
    };
    setMessages(msgs => [msg, ...msgs]);
    sendMsgFn(functions, chatRef.id, {id: msg.id, timestamp: now, text: inputMessage})
      .catch(err => alert(`Failed to send message: ${err.message}`));
    setInputMessage('');
  };
  useEffect(() => {
    if (!messages.length) return;
    if (messages[0]?.timestamp?.toMillis() > lastMsgAt) {
      setLastMsgAt(messages[0].timestamp.toMillis());
      scrollDivRef.current.scrollTo({top: 0, behavior: 'smooth'});
    }
  }, [messages, lastMsgAt]);

  return (
    <div className={hidden ? cn.rootHidden : cn.root}>
      <div 
        ref={scrollDivRef}
        className={cn.messagesContainer} /* reverse-column */
        onScroll={onScroll}>
        {loading ? 
          <div className={cn.initCircle}><CircularProgress/></div>
        : 
          messages.map((curMsg, i) => 
            <Message 
              key={`${curMsg.id}${moment().valueOf()}`} // always rerender on changes cause chrome v89.0.4389 sucks 
              {...{cn, userID, chatID: chatRef.id, admin, functions, curMsg, nextMsg: messages[i+1]}}
            />,
          )
        }
        <div className={cn.earlyCircleOuter}>
          <div className={cn.earlyCircleInner} style={{visibility: loadingEarlier ? 'visible' : 'hidden'}}>
            <CircularProgress size={20}/>
          </div>
        </div>
      </div>
      <div className={cn.inputContainer}>
        <TextField 
          className={cn.input}
          variant="outlined"
          fullWidth
          multiline
          value={inputMessage}
          onChange={ev => setInputMessage(ev.target.value)}
          placeholder="Chat with customer ..."
        />
        <IconButton
          children={<SendIcon/>}
          color='primary'
          onClick={onClickSend}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    flex: 1,
  },
  rootHidden:{
    display: 'none',
  },
  messagesContainer: {
    height: 350,
    margin: '0 0 0 5%',
    padding: '10px 5% 5% 0',
    overflowY: 'scroll !important',
    display: 'flex',
    flexDirection: 'column-reverse',
    // make scrollbar always visible:
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0,0,0,.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255,255,255,.5)',
    },

    // firefox flexbox issues:
    // https://onfe.co.uk/blog/p/the-woes-of-firefox-s-flexbox/
    ...isFirefox && {
      flexDirection: 'column',
      transform: 'scaleY(-1)',
    },
  },
  initCircle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  earlyCircleOuter: {
    width: '100%',
    height: 40,
  },
  earlyCircleInner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: isFirefox ? 'flex-start' : 'flex-end',
    alignItems: 'center',
  },
  messageRow: {
    width: '100%',
    marginTop: 3,

    // firefox flexbox issues:
    ...isFirefox && {
      transform: 'scaleY(-1)',
    },
  },
  userRow: {
    extend: 'messageRow',
    alignItems: 'flex-end',
  },
  otherRow: {
    extend: 'messageRow',
    alignItems: 'flex-start',
  },
  rejectedRow: {
    opacity: 0.5,
  },
  dateText: {
    padding: 5,
    color: 'rgba(0,0,0,0.5)',
    alignSelf: 'center',
  },
  nameText: {
    paddingBottom: 5,
    color: 'rgba(0,0,0,0.5)',
  },
  timeText: {
    padding: '0px 10px',
    color: 'rgba(0,0,0,0.4)',
    transition: 'all .3s ease-in-out',
    opacity: 1,
  },
  timeTextHidden: {
    extend: 'timeText',
    opacity: 0,
  },
  msgItemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userMsgItemContainer: {
    extend: 'msgItemContainer',
    justifyContent: 'flex-end',
  },
  otherMsgItemContainer: {
    extend: 'msgItemContainer',
    justifyContent: 'flex-start',
  },
  dateContainer: {
    extend: 'msgItemContainer',
    justifyContent: 'center',
  },
  bubble: {
    width: 'fit-content',
    maxWidth: '80%',
    borderRadius: 10,
    padding: '5px 10px',
    display: 'flex',
  },
  userBubble: {
    extend: 'bubble',
    paddingRight: '5%',
    alignSelf: 'flex-end',
    backgroundColor: '#1F8DCD',
    color: 'white',
  },
  otherBubble: {
    extend: 'bubble',
    paddingLeft: '5%',
    alignSelf: 'flex-start',
    backgroundColor: 'rgba(0,0,0,0.05)',
    color: 'black',
  },
  messageText: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    fontSize: '1.05em',
    lineHeight: '1.1em',
  },
  reviewButton: {
    margin: '5px 2px',
    minWidth: 50,
    borderRadius: 10,
  },
  inputContainer: {
    padding: 10,
    //borderTop: '1px solid rgba(0,0,0,0.42)',
    backgroundColor: 'rgba(0,0,0,0.05)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    marginRight: 10,
    backgroundColor: 'white',
  },
});

export default Chat;
