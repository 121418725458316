import React, {useState} from 'react';
import {
  filterActiveQuotes,
  filterCompletedQuotes,
  filterExpiredQuotes,
  filterLiveTestQuotes,
  filterPresubmitQuote,
  filterRejectedQuotes,
} from "../../../util/status";
import {useRecoilValue, useSetRecoilState} from 'recoil';

import CustomerListing from "./Listing/CustomerListing";
import Filters from '../Detail/customer/Filters';
import IndexFilter from "./IndexFilter";
import IndexHeader from "./IndexHeader";
import IndexTabs from "./IndexTabs";
import _ from 'lodash';
import {makeStyles} from "@material-ui/styles";
import state from '../../../state';

const useStyles = makeStyles({
  customersIndex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '25px',
  },
  emptyText: {
    textAlign: 'center',
  },
  select: {
    maxWidth: '250px',
  },
});

function filterFreight(r) {
  return _.get(r, 'appID', '').toLowerCase() === 'muva freight';
}

function FreightIndex() {
  const 
    styles = useStyles(),
    customers = useRecoilValue(state.customers.sorted),
    setCustomersSort = useSetRecoilState(state.customers.sort),
    [currentTab, setCurrentTab] = useState('ACTIVE'),

    [liveCustomers, testCustomers] = filterLiveTestQuotes(customers),
    presubmitCustomers = filterPresubmitQuote(liveCustomers).filter(filterFreight),
    activeCustomers = filterActiveQuotes(liveCustomers).filter(filterFreight),
    expiredCustomers = filterExpiredQuotes(liveCustomers).filter(filterFreight),
    completedCustomers = filterCompletedQuotes(liveCustomers).filter(filterFreight),
    rejectedCustomers = filterRejectedQuotes(liveCustomers).filter(filterFreight);
  
  const [search, setSearch] = useState('');

  function handleChange(event, newValue) {
    setCurrentTab(newValue);
  }

  function handleSortCustomers(key, dir) {
    return () => setCustomersSort({key, dir});
  }

  const data = currentTab === 'PRESUBMIT' ? presubmitCustomers
  : currentTab === 'ACTIVE' ? activeCustomers 
  : currentTab === 'COMPLETED' ? completedCustomers
  : currentTab === 'EXPIRED' ? expiredCustomers
  : currentTab === 'REJECTED' ? rejectedCustomers
  : testCustomers;

  const filteredData = () => {
    let filteredArr = data;
    if (search.length) 
      filteredArr = filteredArr.filter((r) => _.get(r, 'contact.name', '').toLowerCase().includes(search.toLocaleLowerCase()))
    return filteredArr;
  }

  const finalData = filteredData();

  return (
    <div className={styles.customersIndex}>
      <IndexHeader
        title={"Freight"}
      />

      <IndexTabs
        currentTab={currentTab}
        handleChange={handleChange}
        tabs={[
          {value: "PRESUBMIT", label: `Presubmit - ${presubmitCustomers.length}`},
          {value: "ACTIVE", label: `Active - ${activeCustomers.length}`},
          {value: 'EXPIRED', label: `Expired - ${expiredCustomers.length}`},
          {value: "COMPLETED", label: `Completed - ${completedCustomers.length}`},
          {value: "REJECTED", label: `Rejected - ${rejectedCustomers.length}`},
          {value: "TEST", label: `Test - ${testCustomers.length}`},
        ]}
      />

      <IndexFilter
        filterButtons={
          [
            {label: "Name ASC", func: handleSortCustomers('contact.name', 'asc')},
            {label: "Name DESC", func: handleSortCustomers('contact.name', 'desc')},
            {label: "Status ASC", func: handleSortCustomers('state', 'asc')},
            {label: "Status DESC", func: handleSortCustomers('state', 'desc')},
            {label: "Modified ASC", func: handleSortCustomers('lastModified', 'asc')},
            {label: "Modified DESC", func: handleSortCustomers('lastModified', 'desc')},
          ]
        }
        custom={
          <Filters
            search={search}
            onSearch={(e) => setSearch(e.target.value)}
          />
        }
      />

      <CustomerListing
        data={finalData}
      />

      {
        finalData.length === 0 && <p className={styles.emptyText}>No matches for your search</p>
      }
    </div>
  );
}

export default FreightIndex;
