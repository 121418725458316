import React from 'react';
import {useSetRecoilState} from 'recoil';
import state from '../../../../state';
import {List} from "@material-ui/core";
import ListingRow from "./ListingRow";

function MoverListing(props) {
  const setDetailsID = useSetRecoilState(state.movers.detailsID);
  const onDetailOpen = (details) => setDetailsID(details.id);

  return (
    <List>
      {props.data.map(m => {
        return (
          <ListingRow
            data={m}
            leftPrimary={m.name}
            leftSecondary={`${m.email} - ${m.phone}`}
            rightPrimary={`Moves Completed: ${m.movesComplete}`}
            buttonLabel={'Details'}
            buttonAction={onDetailOpen}
            key={`${m.name}-listing-${Math.random()}`}
          />
        )
      })}
    </List>
  );
}

export default MoverListing;
