import React, {useEffect, useState, useRef} from 'react';
import {Collapse} from '@material-ui/core';
import {Alert as MuiAlert} from '@material-ui/lab';

const useTimeout = (ms) => {
  const curTimeout = useRef(null);
  const timeoutFunc = useRef((onTimeout) => {
    if (curTimeout.current)
      clearTimeout(curTimeout.current);
    
    curTimeout.current = setTimeout(() => {
      curTimeout.current = null;
      onTimeout();
    }, ms);
  });

  return timeoutFunc.current;
}

const Alert = ({className, children, severity, flipShow}) => {
  const lastFlip = useRef(flipShow);
  const setHideTimeout = useTimeout(5000)
  const [show, setShow] = useState(false);
  
  useEffect(() => {
    if (flipShow !== lastFlip.current) {
      lastFlip.current = flipShow;
      setShow(true);
      setHideTimeout(() => setShow(false));
    };
  }, [flipShow]);

  return (
    <div className={className}>
      <Collapse in={show}>
        <MuiAlert severity={severity}>
          {children}
        </MuiAlert>
      </Collapse>
    </div>
  );
};

export default Alert;
