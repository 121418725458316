import React from 'react';
import {useRecoilValue} from 'recoil';
import {makeStyles} from "@material-ui/styles";

import state from '../../state';
import Sidebar from './Sidebar';
import CustomersPanel from './CustomersPanel';
import MoversPanel from './MoversPanel';
// import DiscountsPanel from './DiscountsPanel';
import Navbar from '../Navbar/Navbar';
import MuvaFreightPanel from './MuvaFreightPanel';
import QuotePanel from './QuotePanel';

const useStyles = makeStyles({
  root: {
    minHeight: '100%',
    backgroundColor: '#FAFAFA',
    display: 'flex',
    flexDirection: 'column',
  },
  cols: {
    flex: 1,
    display: 'flex',
    flexDirection: "row",
  },
});

function Dashboard() {
  const cn = useStyles();
  const panelState = useRecoilValue(state.app.panel);
  
  return (
    <div className={cn.root}>
      <Navbar />
      <div className={cn.cols} >
        <Sidebar />
        {
          (() => {
            switch (panelState) {
              case 'CUSTOMER':
                return <CustomersPanel />;
              case 'MOVER':
                  return <MoversPanel />;
               case 'MUVA FREIGHT':
                 return <MuvaFreightPanel />;
                 case 'QUOTE':
                 return <QuotePanel />;
              default:
                return null;
            }
          })()
        }
      </div >
    </div>
  );
}

export default Dashboard;
