import React, {useEffect, useState} from 'react';
import {FormControlLabel, Switch, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const AsyncSwitch = ({label, value, onChange, onError}) => {
  const cn = useStyles();
  const [checked, setChecked] = useState(value);
  const [loading, setLoading] = useState(false);

  const handleChange = evt => {
    const oldVal = checked;
    const newVal = evt.target.checked;
    setChecked(newVal);

    setLoading(true);
    onChange(newVal)
      .catch(err => {
        setChecked(oldVal);
        onError && onError(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => setChecked(value), [value]);

  return <>
    <FormControlLabel
      disabled={loading}
      checked={checked}
      onChange={handleChange}
      name={`checked-${label}`}
      control={<Switch />}
      label={
        label && <div className={cn.label}>
          {label}
          {loading &&<CircularProgress className={cn.progress} size={15} color="primary" />}
        </div>
      }/>
  </>
};

const useStyles = makeStyles({
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  progress: {
    marginLeft: 10,
  },
})

export default AsyncSwitch