/* eslint-disable comma-dangle */
import _ from 'lodash';
import React, {useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {makeStyles} from "@material-ui/styles";
import {Button, Paper, FormControl, MenuItem, InputLabel, Select} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

import state from 'state';
import {userID, getChatRef, functions} from '../../../firebase';
import {AdminStates, getQuoteStateText, getStateText} from "util/status"

import {OverlapDiv, Chat} from "lib";
import ActionButtons from "./customer/ActionButtons";
import CloseRequestForm from './customer/CloseRequestForm';
import AssignMoverForm from "./customer/AssignMoverForm";
import SetDeadlineForm from "./customer/SetDeadlineForm";
import VideoSelect from "./customer/VideoSelect";
import CustomerInfo from "./customer/CustomerInfo";
import LinkInfo from "./customer/LinkInfo";
import DetailHistory from "./customer/DetailHistory";
import FeedbackInfo from "./customer/FeedbackInfo";
import AccountInfo from './customer/AccountInfo';
import AssignInfo from './customer/AssignInfo';
import ServiceInfo from './customer/ServiceInfo';
import QuoteInfo from "./customer/deprecated/QuoteInfo";
import Accounting from "./customer/deprecated/Accounting";

function CustomerDetail({isMover = false}) {
  const
    cn = useStyles(),
    setDetailsID = useSetRecoilState(state.customers.detailsID),
    onCloseDetails = () => setDetailsID(null),
  
    details = useRecoilValue(state.customers.details),
    {activeMovers} = useRecoilValue(state.movers.filtered),
    chats = _.map(details.chats, (chatID, chatWithID) => ({
      chatID, 
      chatWith: chatWithID === 'admin' ? 'Admin' : details.assigns[chatWithID].proName
    })),
    [showCloseForm, setShowCloseForm] = useState(false),
    [showAssignForm, setShowAssignForm] = useState(false),
    [showDeadlineForm, setShowDeadlineForm] = useState(false),
    [curChatID, setCurChatID] = useState(details?.chats.admin),
    fadeButtons = showCloseForm || showAssignForm || showDeadlineForm,
    stateText = details.version === 1 ? getQuoteStateText(details.state) : getStateText(details.state);

  return (
    <div className={cn.mainPanel}>
      {details.version === 1 && 
        <Alert className={cn.alert} severity="warning">
          <span>Ver. 1 quote. This will need to be converted to Ver. 2 before further action.</span>
        </Alert>
      }
      <div className={cn.headerSection}>
        <div>
          <div className={cn.title}>State: {stateText}</div>
          <span className={cn.subTitle}>Request ID: {details.id}</span>
          { details.moverRef && 
            <div><span className={cn.subTitle}>Referral To: {details.moverRefName}</span></div>
          }
          { details.state === AdminStates.canceled && details.cancelInfo && 
            <div><span className={cn.subTitle}>Cancel Reason: {details.cancelInfo.reason}</span></div>
          }
        </div>
        <Button
          classes={{root: cn.backButton}}
          variant="contained"
          onClick={onCloseDetails}>
          <span className={cn.buttonLabel}>Back</span>
        </Button>
      </div>
      <div className={cn.panelCols}>
        <div className={cn.infoSection}>
          {!!details.videos?.length && details.state !== AdminStates.init &&
            <VideoSelect orderID={details.id} videos={details.videos}/>
          }
          <CustomerInfo details={details} />
          {!_.isEmpty(details.assigns) && <ServiceInfo details={details} />}
          {!_.isEmpty(details.assigns) && <AssignInfo details={details} />}
          {(!_.isEmpty(details.links) || 
            !_.isEmpty(details.assigns)) && <LinkInfo details={details}/>}
          {details.account && <AccountInfo details={details}/>}
          {<FeedbackInfo details={details}/>}
          {details.version && <DetailHistory details={details}/>}

          {/* For Order/Quotes version 1: */}
          {details.accounting && <Accounting details={details}/>}
          {details.quote && <QuoteInfo details={details}/>}
        </div>
        {
          !isMover && <div className={cn.actionSection}>
          {details.chats &&
            <Paper elevation={1} className={cn.chatPanel}>
              <FormControl className={cn.chatSelect}>
                <InputLabel htmlFor="chat-select" shrink>Chat</InputLabel>
                <Select
                  value={curChatID}
                  onChange={evt => setCurChatID(evt.target.value)}
                  inputProps={{
                    name: 'Chat',
                    id: 'chat-select',
                  }}>
                  {chats.map(({chatID, chatWith}) =>
                    <MenuItem value={chatID} key={chatID}>{chatWith}</MenuItem>
                  )}
                </Select>
              </FormControl>
              {chats.map(({chatID}) => 
                <Chat
                  key={chatID}
                  hidden={chatID !== curChatID}
                  chatRef={getChatRef(chatID)}
                  functions={functions}
                  userID={userID()}
                  admin />
              )}
            </Paper>
          }
          <div className={cn.forms}>
            <OverlapDiv>
              <ActionButtons
                fade={fadeButtons}
                details={details}
                closeFormToggle={() => setShowCloseForm(!showCloseForm)}
                assignFormToggle={() => setShowAssignForm(!showAssignForm)}
                deadlineFormToggle={() => setShowDeadlineForm(!showDeadlineForm)}
                orderID={details.id}/>
            </OverlapDiv>
            <OverlapDiv>
              <CloseRequestForm
                collapse={!showCloseForm}
                details={details}
                onClose={() => setShowCloseForm(false)}/>
            </OverlapDiv>
            <OverlapDiv>
              <AssignMoverForm
                collapse={!showAssignForm}
                details={details}
                pros={activeMovers}
                onClose={() => setShowAssignForm(false)}/>
            </OverlapDiv>
            <OverlapDiv>
              <SetDeadlineForm
                collapse={!showDeadlineForm}
                details={details}
                pros={activeMovers}
                onClose={() => setShowDeadlineForm(false)}/>
            </OverlapDiv>
          </div>
        </div>
        }
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  mainPanel: {
    padding: '25px 0 100px 25px',
    maxWidth: 1050,
  },
  headerSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  backButton: {
    background: 'white',
    width: 100,
  },
  alert: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10,
  },
  title: {
    fontSize: '30px',
  },
  subTitle: {
    fontSize: '20px',
  },
  panelCols: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoSection: {
    marginRight: 30,
    width: 530,
  },
  actionSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  chatPanel: {
    width: 400,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  chatSelect: {
    margin: '20px 25px 10px',
  },
  forms: {
    position: 'relative',
    width: 400,
  },
  button: {
    width: "240px",
    fontWeight: "600",
    backgroundColor: '#0E75BB',
    color: 'white',
    '&:hover': {
      backgroundColor: '#78A3C0',
    },
  },
});

export default CustomerDetail;
