
/// version 2 orders:
export const AdminStates = {
  // active:
  init: 'init',
  new: 'new',
  review: 'review',
  booked: 'booked',
  ongoing: 'ongoing',
  // inactive:
  completed: 'completed',
  expired: 'expired',
  canceled: 'canceled',
  adminRejected: 'adminRejected',
  customerRejected: 'customerRejected',
  providerRejected: 'providerRejected',
};
export const AssignStates = {
  assigned: 'assigned',
  providerExpired: 'providerExpired',
  providerRejected: 'providerRejected',
  quoted: 'quoted',
  customerExpired: 'customerExpired',
  customerRejected: 'customerRejected',
  booked: 'booked',
  canceled: 'canceled',
};
export const ServiceStates = {
  new: 'new',
  booked: 'booked',
  canceled: 'canceled',
  reserved: 'reserved',
  reserveReview: 'reservedReview',
  authFailed: 'authFailed',
  authorized: 'authorized',
  introTerm: 'introTerm',
  recurTerm: 'recurTerm',
  awaitInvoice: 'awaitInvoice',
  invoiced: 'invoiced',
  paymentFailed: 'paymentFailed',
  addCharge: 'addCharge',
  completed: 'completed',
};
export const LogActions = {
  ...AdminStates,
  ...AssignStates,
  ...ServiceStates,
  incAuth: 'incAuth',
  reauth: 'reauth',
};

export const getStateText = (state) => {
  const s = LogActions;
  switch (state) {
    // Admin States:
    case s.init: return 'Presubmit';
    case s.new: return 'New';
    case s.review: return 'In Review';
    case s.booked: return 'Booked';
    case s.ongoing: return 'On Going';
    case s.completed: return 'Completed';
    case s.expired: return 'Expired';
    case s.canceled: return 'Canceled';
    case s.adminRejected: return 'Admin Rejected';
    case s.providerRejected: return 'Mover Rejected';
    case s.customerRejected: return 'Customer Rejected';

    // Assign States:
    case s.assigned: return 'Assigned';
    case s.providerExpired: return 'Mover Expired';
    case s.quoted: return 'Quoted';
    case s.customerExpired: return 'Customer Expired';

    // Service States:
    case s.reserved: return 'Reserved';
    case s.reserveReview: return 'Reserved Review';
    case s.authFailed: return 'Authorization Failed';
    case s.authorized: return 'Authorized';
    case s.introTerm: return 'Intro Term';
    case s.recurTerm: return 'Recurring Term';
    case s.awaitInvoice: return 'Await Invoice';
    case s.invoiced: return 'Invoiced';
    case s.paymentFailed: return 'Payment Failed';
    case s.addCharge: return 'Additional Charge';

    case s.incAuth: return 'Authorization Increase';
    case s.reauth: return 'Reauthorization';

    default: return 'Unknown';
  };
}

/// version 1 orders
export const QuoteStateType = {
  INIT: 'INIT',
  // newly created
  NEW: "NEW",
  // resubmitted quote
  RESUBMIT: 'RESUBMIT',
  // rejected by admin
  ADMIN_REJECTED: "ADMIN_REJECTED",
  // in review for quote generation by mover
  MOVER_REVIEW: "MOVER_REVIEW",
  // rejected by mover
  MOVER_REJECTED: "MOVER_REJECTED",
  // mover did not respond in time limit
  MOVER_EXPIRED: "MOVER_EXPIRED",
  // in review by customer
  CUSTOMER_REVIEW: "CUSTOMER_REVIEW",
  // quote rejected by customer
  CUSTOMER_REJECTED: "CUSTOMER_REJECTED",
  // quote expired for customer
  CUSTOMER_EXPIRED: "CUSTOMER_EXPIRED",
  // quote accepted by customer
  CUSTOMER_ACCEPTED: "CUSTOMER_ACCEPTED",
  // quote accepted by customer, move will happen
  BOOKED: "BOOKED",
  // payment has been authorized 4 days in advance
  AUTHORIZED: "AUTHORIZED",
  // payment authorization failed, will be canceld if not reckified
  AUTHORIZE_FAILED: "AUTHORIZE_FAILED",
  // canceled after move booked (final state)
  CANCELED: "CANCELED",
  NEEDS_INVOICE: "NEEDS_INVOICE",
  // mover submitted invoice
  INVOICED: "INVOICED",
  // payment declined by swipe
  PAYMENT_FAILED: "PAYMENT_FAILED",
  // stripe payment failed and additional charges exist
  ADDITIONAL_CHARGE_AND_PAYMENT_FAILURE: "ADDITIONAL_CHARGE_AND_PAYMENT_FAILURE",
  // stripe complete, additional charges present
  ADDITIONAL_CHARGE: "ADDITIONAL_CHARGE",
  COMPLETED: "COMPLETED",
};

export const getQuoteStateText = (quoteState, short = true) => {
  switch (quoteState) {
    case QuoteStateType.INIT:
      return 'Initalizing ...'
    case QuoteStateType.NEW:
      return short
        ? 'New Request'
        : 'New Quote Request Created by Customer';
    case QuoteStateType.RESUBMIT:
      return short
        ? 'Resubmitted Request'
        : 'Quote Request Resubmitted by Customer';
    case QuoteStateType.ADMIN_REJECTED:
      return 'Rejected By Admin';
    case QuoteStateType.MOVER_REVIEW:
      return short
        ? 'Mover Review'
        : 'Quote Request Sent To Mover';
    case QuoteStateType.MOVER_REJECTED:
      return short
        ? 'Mover Declined'
        : 'Mover Declined To Quote Request';
    case QuoteStateType.MOVER_EXPIRED:
      return short
        ? 'Mover Review Expired'
        : 'Mover Review Time Expired';
    case QuoteStateType.MOVER_INVOICE:
      return short
        ? 'Awaiting Mover Invoice'
        : 'Invoice Request Sent To Mover';
    case QuoteStateType.CUSTOMER_REVIEW:
      return short
        ? 'Quote Sent To Customer'
        : 'Mover Quote Sent To Customer';
    case QuoteStateType.CUSTOMER_REJECTED:
      return short 
        ? 'Customer Rejected'
        : 'Customer Rejected Mover Quote';
    case QuoteStateType.CUSTOMER_ACCEPTED:
      return short
        ? 'Customer Accepted'
        : 'Customer Accepted Mover Quote';
    case QuoteStateType.PAYMENT_FAILED:
      return 'Payment Declined';
    case QuoteStateType.COMPLETED:
      return 'Move Complete';
    case QuoteStateType.NEEDS_INVOICE:
      return 'Awaiting Mover Invoice';
    case QuoteStateType.CUSTOMER_EXPIRED:
      return short
        ? 'Customer Review Expired'
        : 'Quote For Customer Expired';
    case QuoteStateType.BOOKED:
      return 'Move Booked!';
    case QuoteStateType.CANCELED:
      return 'Move Canceled';
    case QuoteStateType.AUTHORIZED:
      return short
        ? 'Booked and Authorized!'
        : 'Move book and full payment authorized';
    case QuoteStateType.AUTHORIZE_FAILED:
      return 'Payment Authorize Failed';
    case QuoteStateType.INVOICED:
      return 'Mover Submitted Invoice';
    case QuoteStateType.ADDITIONAL_CHARGE_AND_PAYMENT_FAILURE:
      return 'Payment (Stripe) Failure - Additional Charges Needed';
    case QuoteStateType.ADDITIONAL_CHARGE:
      return short 
        ? 'Additional Charges'
        : 'Awaiting approval of final price';
    default:
      return getStateText(quoteState);
  }
};

////
// TODO:
//
// These kinds of state machine constraints should really be derived from
// the same library used by MuvaFunctions
///

export const allowAssignMover = (state) => {
  return state === AdminStates.new
    || state === AdminStates.review;
};

export const allowSetExpiration = (state) => {
  return state === AdminStates.review
};

export const allowResend = (state) => {
  return state === QuoteStateType.MOVER_REVIEW
    || state === QuoteStateType.CUSTOMER_REVIEW
    || state === QuoteStateType.NEEDS_INVOICE
    || state === QuoteStateType.COMPLETED;
};

export const allowClose = (state) => {
  return state !== AdminStates.booked
    && state !== AdminStates.ongoing
    && state !== AdminStates.completed
};

export const allowRetryPayment = (state) => {
  return state === QuoteStateType.PAYMENT_FAILED
    || state === QuoteStateType.ADDITIONAL_CHARGE_AND_PAYMENT_FAILURE;
};

export const allowMarkCompleted = (state) => {
  return state === QuoteStateType.PAYMENT_FAILED
    || state === QuoteStateType.ADDITIONAL_CHARGE
    || state === QuoteStateType.ADDITIONAL_CHARGE_AND_PAYMENT_FAILURE;
};

export const filterLiveTestQuotes = (quotes) =>
  [quotes.filter(q => !q.test), quotes.filter(q => q.test)];

export const filterPresubmitQuote = (quotes) =>
  quotes.filter(
    q => q.state === AdminStates.init,
  );


export const filterActiveQuotes = (quotes) =>
  quotes.filter(
      // version 1
    q => q.state !== QuoteStateType.ADMIN_REJECTED
      && q.state !== QuoteStateType.COMPLETED
      && q.state !== QuoteStateType.CUSTOMER_EXPIRED
      // version 2
      && q.state !== AdminStates.init
      && q.state !== AdminStates.completed
      && q.state !== AdminStates.adminRejected
      && q.state !== AdminStates.providerRejected
      && q.state !== AdminStates.customerRejected
      && q.state !== AdminStates.canceled
      && q.state !== AdminStates.expired,
  );

export const filterExpiredQuotes = (quotes) =>
  quotes.filter(
    // version 1
    q => q.state === QuoteStateType.CUSTOMER_EXPIRED
    // version 2
      || q.state === AdminStates.expired,
  );

export const filterCompletedQuotes = (quotes) =>
  quotes.filter(
      // version 1
    q => q.state === QuoteStateType.COMPLETED
      // version 2
      || q.state === AdminStates.completed,
  );

export const filterRejectedQuotes = (quotes) =>
  quotes.filter(
      // version 1
    q => q.state === QuoteStateType.ADMIN_REJECTED
      || q.state === QuoteStateType.CUSTOMER_REJECTED
      // version 2
      || q.state === AdminStates.canceled
      || q.state === AdminStates.adminRejected
      || q.state === AdminStates.providerRejected
      || q.state === AdminStates.customerRejected,
  );