import _ from 'lodash';
import React from "react";
import {Paper} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";
import {getStateText, ServiceStates} from "../../../../util/status"
import {$tr, capitalize} from '../../../../util/util'
import * as Firebase from '../../../../firebase';
import {ActionButton} from 'lib';

const ServiceInfo = ({details}) => {
  const cn = useStyles();

  return (
    <Paper elevation={1} className={cn.serviceInfo}>
      <span className={cn.infoTitle}>Services</span>
      { _.map(details.services, (service, serviceType) => {
        const charges = _.filter(details.account.charges, txn => txn.serviceType === serviceType);
        const payouts = _.filter(details.account.payouts, txn => txn.serviceType === serviceType);
        return (
          <div key={serviceType} className={cn.section}>
            <div className={cn.sectionTitle}>
              {capitalize(serviceType)}: {getStateText(service.state)}
            </div>
            {charges 
              && charges.some(txn => txn.type === 'payment')
              && !charges.some(txn => !txn.available)
              && payouts?.length === 0
              &&
               <ActionButton
                onClick={() => Firebase.payoutQuote(details.id, serviceType)}
                onError={err => alert(err.message)}>
                Payout
              </ActionButton>
            }
            {service.state === ServiceStates.addCharge &&
               <ActionButton
                onClick={() => Firebase.approveInvoice(details.id, serviceType)}
                onError={err => alert(err.message)}>
                {`Approve (total ${$tr(service.cost.payment)})`}
              </ActionButton>
            }
          </div>)
      })}
    </Paper>
  );
};

const useStyles = makeStyles({
  serviceInfo: {
    marginTop: 30,
    marginBottom: 30,
    padding: '20px 20px 20px 20px',
    minWidth: 450,
  },
  infoTitle: {
    fontSize: 30,
  },
  section: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  sectionTitle: {
    fontSize: 20,
    marginRight: 20,
  },
  quoteSection: {

  },
  quoteTitle: {

  },
});

export default ServiceInfo;
