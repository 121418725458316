import _ from 'lodash';
import React from "react";
import {Paper} from '@material-ui/core';
import {Table, TableBody, TableRow, TableCell} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";

import {getStateText} from "../../../../util/status"
import * as util from '../../../../util/util';
const {$tr} = util;

const QuoteInfo = ({quote}) => {
  // const cn = useStyles();
  return (
    <TableRow>
      <TableCell>
        <div>{util.capitalize(quote.serviceType)}</div>
        <div>{util.dateStr(quote.at)}</div>
        <div>{util.timeStr(quote.at)}</div>
      </TableCell>
      <TableCell>
        {quote.serviceType === 'storage' ? 
          <>
            <div>Initial: {$tr(quote.price.init)}</div>
            <div>Extension: {$tr(quote.price.recur)}/mo</div>
          </>
        : 
          <div>Price: {$tr(quote.price.min)}-{$tr(quote.price.max)}</div>
        }
        <div>Deposit: {$tr(quote.deposit)}</div>
      </TableCell>
      {/* <DetailLine
        label={util.capitalize(quote.serviceType)}
        values={[
          util.timeDateStr(quote.at),
        ]}
      />
      <DetailLine
        label="Price"
        values={[
          `Consumer: ${$tr(quote.price.min)}-${$tr(quote.price.max)}`,
          `Mover: ${$tr(quote.price.pro.min)}-${$tr(quote.price.pro.max)}`,
      ]}
      />
      <DetailLine
        label="Deposit"
        values={[`${$tr(quote.deposit)}`]}
      /> */}
    </TableRow>
  );
};

const AssignInfo = ({details}) => {
  const assigns = _.map(details.assigns);
  const cn = useStyles();

  return (
    <Paper elevation={1} className={cn.assignInfo}>
      <span className={cn.infoTitle}>Assignments</span>
      {assigns.map(assign => {
        const quotes = _.map(details?.quotes[assign.proID], 
          (val, key) => ({...val, serviceType: key}),
        );
        return (
          <div key={assign.proID} className={cn.assignSection}>
            <div className={cn.assignTitle}>
              {assign.proName}: {getStateText(assign.state)}
            </div>
            {!!assign.expiresAt && 
              <div>Expires: {util.dateTimeStr(assign.expiresAt)}</div>
            }

            {!!quotes.length && 
              <Table size="small"><TableBody>
                {quotes.map(quote => <QuoteInfo key={assign.proID+'-'+quote.serviceType} quote={quote}/>)}
              </TableBody></Table>
            }
          </div>)
      })}
    </Paper>
  );
};

const useStyles = makeStyles({
  assignInfo: {
    marginTop: 30,
    marginBottom: 30,
    padding: '20px 20px 20px 20px',
    minWidth: 450,
  },
  infoTitle: {
    fontSize: 30,
  },
  assignSection: {
    marginTop: 20,
  },
  assignTitle: {
    fontSize: 20,
  },
  quoteSection: {

  },
  quoteTitle: {

  },
});

export default AssignInfo;
