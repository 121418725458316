import React from "react";
import {useRecoilValue} from 'recoil';
import {makeStyles} from "@material-ui/styles";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";

import state from '../../state';
import {logout} from "../../firebase";
import logo from "../../assets/logo.png";

const useStyles = makeStyles({
  navbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 50px",
  },
  navbarHeaderContainer: {
    display: "flex",
  },
  navbarHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
  },
  logOutButton: {
    marginRight: '20px',
  },
});

function Navbar() {
  const styles = useStyles();
  const loggedIn = useRecoilValue(state.app.loggedIn);

  return (
    <AppBar className={styles.navbar} position="static" color="default" elevation={1}>
      <div>
        <img src={logo} alt="logo" />
      </div>
      <div>
      </div>
      <div className={styles.navbarHeaderContainer}>
        {
          loggedIn && (
            <Button className={styles.logOutButton} onClick={logout}>
              Log Out
            </Button>
          )
        }
        <span className={styles.navbarHeader}>Admin Portal</span>
      </div>
    </AppBar>
  );
}

export default Navbar;
