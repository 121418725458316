import React from 'react';
import {Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import * as util from '../../../../util/util';

import DetailLine from '../DetailLine';
import UserInfo from './UserInfo';
import {AdminStates} from 'util/status';

function CustomerInfo({details}) {
  const cn = useStyles();

  const info = details.details;
  const {contact, pickup, destination} = details;

  const addrVal = (addr) =>
    addr.address
      ? [
          `${addr.address}${addr.apt ? ',' + addr.apt : ''}`,
          `${addr.city}, ${addr.state}, ${
            addr.country ? addr.country + ', ' : ''
          }${addr.zip}`,
          'Bedrooms - ' + addr.bedroomCount,
          'Parking - ' + addr.parking,
        ]
      : [addr.zip || ''];

  return (
    <Paper elevation={1} className={cn.detailsSection}>
      <DetailLine
        label="Customer Contact Info"
        values={[contact.name, contact.email, contact.phone]}
      />
      {details.promoID && (
        <DetailLine label="Referral ID" values={[details.promoID]} />
      )}
      {!!details.priority?.length && (
        <DetailLine
          label="Priorities"
          values={[details.priority?.join(', ')]}
        />
      )}
      {details.moveType && (
        <>
          <DetailLine label="Move Type" values={[details.moveType]} />
          <DetailLine label="Pickup" values={addrVal(pickup)} />
          <DetailLine label="Destination" values={addrVal(destination)} />
        </>
      )}
      {info.moveWindow && (
        <>
          <DetailLine
            label="Move Date"
            values={[util.dateStr(info.moveDate)]}
          />
          <DetailLine label="Time Window" values={[info.moveWindow]} />
        </>
      )}
      {info.storageRequired && info.moveInDate && (
        <DetailLine
          label="Move-In Date"
          values={[util.dateStr(info.moveInDate)]}
        />
      )}
      {(info.largeItems ||
        info.noPacking ||
        info.specialPacking ||
        info.storageRequired) && (
        <DetailLine
          label="Packing Details"
          values={[
            info.largeItems && 'Large or Heavy Items',
            info.noPacking ? 'Self-pack' : 'Packing Required',
            info.boxes && 'Boxes Required',
            info.specialPacking && 'Special Packing Required',
            info.storageRequired && 'Storage Required',
          ]}
        />
      )}
      {info.requirements && (
        <DetailLine label="Requirements" values={[info.requirements]} />
      )}
      {!!details.videos?.length && details.state === AdminStates.init && (
        <DetailLine label="Video" values={['Recorded']} />
      )}
      {details.details.notes && (
        <DetailLine label="Customer Notes" values={[details.details.notes]} />
      )}
      {details.adminNotes && (
        <DetailLine label="Admin Notes" values={[details.adminNotes]} />
      )}
      {details.moveNotes && (
        <DetailLine label="Move Notes" values={[details.moveNotes]} />
      )}
      {details.closeNotes && (
        <DetailLine label="Close Notes" values={[details.closeNotes]} />
      )}

      <UserInfo userID={details.userID} />
    </Paper>
  );
}

const useStyles = makeStyles({
  detailsSection: {
    marginBottom: '30px',
    padding: '20px 20px 20px 20px',
    minWidth: 450,
    position: 'relative',
  },
});

export default CustomerInfo;
