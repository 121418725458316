
//handle LocalStorage data here

export function setLocalData(id , data){
    localStorage.setItem(`${id}` ,JSON.stringify(data))
}

export function getLocalData(id){
return JSON.parse(localStorage.getItem(`${id}`))
}

export function removeLocalData(id){
    return localStorage.removeItem(`${id}`)
}