import React from 'react';
import {makeStyles} from "@material-ui/styles";
import OutlinedInput from '@material-ui/core/OutlinedInput';

const useStyles = makeStyles({
    mainWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    searchInput: {
        maxWidth: '200px',
        marginRight: '10px',
    },
    select: {
        minWidth: '100px',
        marginLeft: '5px',
    },
})

export default function Filters({onSearch = () => {}, search = '', onMoverChange = () => {}}) {
    const styles = useStyles();

    return <div className={styles.mainWrapper}>
        <OutlinedInput type="search" margin="dense" placeholder="Search..." className={styles.searchInput} value={search} onChange={onSearch}/>
    </div>
}