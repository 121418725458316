import React, {useEffect} from 'react';
import {getCustomers, getDiscounts, getMovers} from '../../firebase';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import CustomerDetail from './Detail/CustomerDetail';
import Loading from '../Loading';
import MuvaFreightIndex from './Index/MuvaFreightIndex';
import state from '../../state';
import {useClaims} from 'util/useClaims';

function MuvaFreightPanel() {
  const {isMover, loadingClaims, moverId, isAdmin} = useClaims();
  const setCustomers = useSetRecoilState(state.customers.customers)
  const cusLoaded = useRecoilValue(state.customers.loaded);
  const cusDetailsOpen = useRecoilValue(state.customers.isDetailsOpen);
  const setMovers = useSetRecoilState(state.movers.movers);
  const movLoaded = useRecoilValue(state.movers.loaded);
  const setDiscounts = useSetRecoilState(state.discounts.discounts);
  const disLoaded = useRecoilValue(state.discounts.loaded);
  
  useEffect(() => {
    if (!loadingClaims) {
      if (!cusLoaded)
        getCustomers(setCustomers, !isAdmin ? moverId : '');
      
      if (!movLoaded)
        getMovers(setMovers);
  
      if (!disLoaded)
        getDiscounts(setDiscounts);
    }

  }, [cusLoaded, disLoaded, isAdmin, loadingClaims, movLoaded, moverId, setCustomers, setDiscounts, setMovers]);

  return (
    (!cusLoaded || !movLoaded || !disLoaded) ? <Loading />
    : cusDetailsOpen ? <CustomerDetail isMover={isMover} />
    : <MuvaFreightIndex />
  );
}

export default MuvaFreightPanel;
