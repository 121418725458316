import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from "@material-ui/styles";
import {getMoverDetailsForOnboarding} from "../../firebase";
import ErrorDialog from '../Dashboard/ErrorDialog';
import Loading from '../Loading';

const useStyles = makeStyles({
    fullWidth: {
        width: '100%',
    },
    businessForm: {
        marginLeft: '50px',
        marginRight: '50px',
        marginTop: '50px',
    },
    loading: {
        '&div': {
            marginTop: '50%',
        },
    },
})

export default function OnboardingForm() {
    const styles = useStyles();
    const [hasError, setHasError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [dataLoaded, setDataLoaded] = useState(true);
    const [justifiToken, setJustifiToken] = useState('');
    const [bizId, setBizId] = useState('');
    const formRef = useRef(null);

    const getSearchParams = () => {
        const params = (new URL(document.location)).searchParams;
        return {
            proID: params.get('proID') || '',
            token: params.get('token') || '',
        };
    }

    const handleErrorClose = () => setHasError(false);

    useEffect(() => {
        const getInitialData = async () => {
            try {
                setDataLoaded(true);
                const {proID} = getSearchParams();
                const {data: moverData} = await getMoverDetailsForOnboarding(proID);

                setJustifiToken(moverData.justifi.token);
                setBizId(moverData.justifi.businessId);
            } catch(err) {
                setHasError(true);
                if (err) setErrorMsg(err);
            } finally {
                setDataLoaded(false);
                const form = formRef.current;
                if (form) {
                    const handleSubmitted = (data) => {
                        console.log('response from justifi', data);
                    };
                    form.addEventListener("submitted", handleSubmitted);
                    
                    return () => form.removeEventListener("submitted", handleSubmitted)
                }
            }
        };
        getInitialData();
    }, []);

    return (dataLoaded ?
        <div className={styles.loading}>
            <Loading />
        </div>
    :
        <div>
            <div className={styles.businessForm}>
                <justifi-business-form
                    ref={formRef}
                    business-id={bizId}
                    auth-token={justifiToken}
                ></justifi-business-form>
            </div>
            <ErrorDialog
            open={hasError}
            handleClose={handleErrorClose}
            title="Uh oh! Something Went Wrong."
            message={errorMsg}
            />
        </div>
    )
}