import React, {useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import IndexHeader from "./IndexHeader";
import IndexTabs from "./IndexTabs";
import {useRecoilState} from 'recoil';
import state from "../../../state";
import {List} from "@material-ui/core";
import {ListItem, ListItemText} from '@material-ui/core';

const useStyles = makeStyles({
  quoteIndex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '25px',
  },
});

function QuoteIndex() {
  const 
  styles = useStyles(),
  [currentTab, setCurrentTab] = useState('ASSIGNED');
  
  const [Quotes] = useRecoilState(state.quotes.quotes);

function handleChange(event, newValue) {
  setCurrentTab(newValue);
}

  return (
    <div className={styles.quoteIndex}>
      <IndexHeader
        title={"Quote Index"}
      />

      <IndexTabs
        currentTab={currentTab}
        handleChange={handleChange}
        tabs={[
          {value: "ASSIGNED" , label:`Assigned`},
        ]}
      />

      <List>    
        {Quotes.map((item)=>{            
          return (
            <ListItem key={item.proID}>
               <ListItemText>
                  {item.proName}
               </ListItemText>
            </ListItem>
          )
      })}
      </List>
    </div>
  );
}

export default QuoteIndex;
